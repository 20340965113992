import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const Privacy_Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <section className="TermsCondition">
        <Container>
          <div className="heading-holder">
            <h1>Privacy Policy</h1>
          </div>
          <ul>
            <div className="list-holder">
              <p className="product-text">
                Welcome to [Your Company Name]! We are committed to protecting
                your personal information and your right to privacy. If you have
                any questions or concerns about our policy, or our practices
                with regards to your personal information, please contact us at
                [Your Contact Email].
              </p>
            </div>

            <li className="product-text">1. Introduction</li>

            <div className="list-holder">
              <p className="product-text">
                Welcome to [Your Company Name] ("Company", "we", "our", "us")!
                We are committed to protecting your personal information and
                your right to privacy. If you have any questions or concerns
                about our policy, or our practices with regards to your personal
                information, please contact us at [Your Contact Email].
              </p>
            </div>

            <li className="product-text">2. Information We Collect</li>
            <div className="list-holder">
              <p className="product-text">
                We collect personal information that you provide to us when you
                register on the website, make a purchase, or interact with us in
                other ways. The personal information we collect may include:
              </p>

              <p className="product-text">
                Contact Information: Name, email address, phone number, shipping
                address.
              </p>
              <p className="product-text">
                Payment Information: Credit card number, billing address,
                payment details.
              </p>
              <p className="product-text">
                Account Information: Username, password, purchase history.
              </p>
              <p className="product-text">
                Technical Data: IP address, browser type, operating system,
                referral source, and other details about your interactions with
                our website.
              </p>
            </div>

            <li className="product-text">3. How We Use Your Information</li>
            <div className="list-holder">
              <p className="product-text">
                We use the information we collect for various purposes,
                including:
              </p>

              <p className="product-text">
                Processing your transactions and managing your orders.
              </p>
              <p className="product-text">
                Providing and improving our services.
              </p>
              <p className="product-text">
                Communicating with you, including sending marketing and
                promotional communications.
              </p>
              <p className="product-text">
                Customizing your experience on our website.
              </p>
              <p className="product-text">
                Analyzing website usage and trends to improve our website.
              </p>
              <p className="product-text">Complying with legal obligations.</p>
            </div>

            <li className="product-text">4. Sharing Your Information</li>
            <div className="list-holder">
              <p className="product-text">
                We may share your personal information with third parties in the
                following circumstances:
              </p>

              <p className="product-text">
                Service Providers: We may share information with third-party
                vendors, service providers, contractors, or agents who perform
                services for us or on our behalf and require access to such
                information to do that work.
              </p>
              <p className="product-text">
                Business Transfers: We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </p>
              <p className="product-text">
                Legal Requirements: We may disclose your information if required
                to do so by law or in response to valid requests by public
                authorities (e.g., a court or a government agency).
              </p>
            </div>
            <li className="product-text">
              5. Cookies and Tracking Technologies
            </li>
            <div className="list-holder">
              <p className="product-text">
                We use cookies and similar tracking technologies to track
                activity on our website and hold certain information. Cookies
                are files with a small amount of data which may include an
                anonymous unique identifier. You can instruct your browser to
                refuse all cookies or to indicate when a cookie is being sent.
                However, if you do not accept cookies, you may not be able to
                use some portions of our website.
              </p>
            </div>

            <li className="product-text">6. Data Security</li>
            <div className="list-holder">
              <p className="product-text">
                We implement a variety of security measures to maintain the
                safety of your personal information. However, please be aware
                that no method of transmission over the Internet, or method of
                electronic storage is 100% secure, and we cannot guarantee
                absolute security of your information.
              </p>
            </div>

            <li className="product-text">7. Your Data Protection Rights</li>
            <div className="list-holder">
              <p className="product-text">
                Depending on your location, you may have the following rights
                regarding your personal data:
              </p>
              <p className="product-text">
                The right to access – You have the right to request copies of
                your personal data.
              </p>
              <p className="product-text">
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate or complete
                information you believe is incomplete.
              </p>
              <p className="product-text">
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </p>
              <p className="product-text">
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </p>
              <p className="product-text">
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p className="product-text">
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
            </div>

            <li className="product-text">8. Children's Privacy</li>
            <div className="list-holder">
              <p className="product-text">
                Our services are not directed to children under 13. We do not
                knowingly collect personally identifiable information from
                children under 13. If you are a parent or guardian and you are
                aware that your child has provided us with personal information,
                please contact us. If we become aware that we have collected
                personal information from children under age 13 without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
            </div>
            <li className="product-text">9. Changes to This Privacy Policy</li>
            <div className="list-holder">
              <p className="product-text">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the "Last Updated" date at the top of
                this Privacy Policy. You are advised to review this Privacy
                Policy periodically for any changes.
              </p>
            </div>
            <li className="product-text">10. Contact Us</li>
            <div className="list-holder">
              <p className="product-text">
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p className="product-text">By email: [Your Contact Email]</p>
              <p className="product-text">
                By visiting this page on our website: [Your Website Contact Page
                URL]
              </p>
              <p className="product-text">By mail: [Your Company Address]</p>
            </div>
          </ul>
        </Container>
      </section>
    </>
  );
};

export default Privacy_Policy;
