import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import CongratsSuccessmodal from '../../../Common_modal/CongratsSuccessmodal/CongratsSuccessmodal';
const Stepseven = ({ nextStep, prevStep, subPage }) => {
    const [otp, setOtp] = useState(false);
    const [show, setShow] = useState(false);
    const otpClick = () => {
        setOtp(true);
    }

    const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
    return (

        <section className='personal-details-form'>

            <div>
                {subPage === 0 ? (
                    <div>


                        <div className='stepsevenMain'>

                            <div className='imarkcontent'>
                                <p className='mb-0'><FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className='me-2' /> Kindly read carefully the Memorandum of Understanding (MOU)</p>
                            </div>

                            <div className='readMoumain'>
                                <p className='mb-2'>Read MOU</p>
                                <Button type='button' className='moubtn'>View MOU</Button>
                                <Form.Check
                                    type="checkbox"
                                    label="I have read the MOU"

                                />
                            </div>

                            <div className='readMoumain'>
                                <p className='mb-2'>Read the GST Declaration</p>
                                <Button type='button' className='moubtn'>View Declaration</Button>

                            </div>

                            <div className='readMoumain'>
                                <p className='mb-2'>Sign the Documents</p>
                                <span className='mb-2'>(Verify OTP to digitally sign the documents)</span>

                            </div>


                            <Form>
                                <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1" >
                                    <Form.Control type="email" placeholder="9898989889" />
                                    <p className='getotptxt' onClick={otpClick}>Get Otp</p>
                                </Form.Group>

                                {otp && (
                                    <>
                                        <div className='col-lg-2 col-md-4 col-8 mx-auto'>
                                            <div className='row getotpinput'>
                                                <div className='col-lg-3 col-3'>
                                                    <Form.Control type="email" />

                                                </div>
                                                <div className='col-lg-3 col-3'>
                                                    <Form.Control type="email" />

                                                </div>
                                                <div className='col-lg-3 col-3'>
                                                    <Form.Control type="email" />

                                                </div>
                                                <div className='col-lg-3 col-3'>
                                                    <Form.Control type="email" />

                                                </div>
                                            </div>

                                        </div>
                                    </>
                                )}

                            </Form>



                            <div className="text-end apply_now_btn">


                                <div className="registerContinueMain">
                                    <Button onClick={prevStep} className="back-btn me-3">
                                        Back
                                    </Button>
                                    <Button className="tabs-btn" type="button" onClick={nextStep}>
                                        Next
                                    </Button>

                                </div>
                            </div>

                        </div>



                    </div>
                ) : (
                    <div>


                        <div className='stepeight'>
                            <div className='dulysigned'>
                                <p>Documents have been signed successfully</p>
                            </div>

                            <div className='stepeightbtn'>
                                <Button type='button' className='me-3'>View Signed MOU</Button>
                                <Button type='button'>View All E-Signed Docs</Button>
                            </div>

                            <div>
                                <p className='mb-4 notifytxt'><FontAwesomeIcon icon={faCircleCheck} className='circleCheckicon' /> (You will receive documents via e-mail)</p>
                            </div>
                        </div>

                        <div className="text-end apply_now_btn">

                            <div className="registerContinueMain">
                                <Button onClick={prevStep} className="back-btn me-3">
                                    Back
                                </Button>
                                <Button className="tabs-btn" type="button" onClick={handleShow}>
                                    Next
                                </Button>

                            </div>
                        </div>
                    </div>
                )}

                <CongratsSuccessmodal show={show} handleClose={handleClose} />
            </div>



        </section>
    )
}

export default Stepseven