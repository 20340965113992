import React from 'react'
import "./All_cont.css"

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Become_inquiry_form = () => {
    return (
        <>
            <div className='Become_inquiry_form'>
                <div className='text-center'>
                    <h5>-- Inquire Now --</h5>
                </div>
                <Form >
                    <Form.Group  className='form-group'>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter First name"
                            defaultValue="Mark"
                        />                        
                    </Form.Group>

                    <Form.Group  className='form-group'>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Last name"
                        />                        
                    </Form.Group>

                    <Form.Group  className='form-group'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter email"
                        />                        
                    </Form.Group>


                    <Form.Group  className='form-group'>
                        <Form.Label>Mobile No.</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Mobile no."
                        />                        
                    </Form.Group>

                    <Form.Group  className='form-group'>
                        <Form.Label>Inquire For</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select your choice</option>
                            <option value="1">Sell on Boomerbucks</option>
                            <option value="2">Promote your products</option>
                            <option value="3">Become Franchise</option>
                            <option value="3">Become Delivery Partner</option>
                            <option value="3">Become Distributor</option>
                        </Form.Select>
                                          
                    </Form.Group>

                    <div className='text-center'>
                        <button className='btn submit-btn'>Submit</button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Become_inquiry_form