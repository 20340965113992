import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Select from "react-select";
const Stepeight = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <section className="personal-details-form ">
        <div className="stepeight">
          <div className="packaginghead">
            <h3>Packaging Details</h3>
            <p>Are there any other order / item packaging charges?*</p>
          </div>

          <div className="mb-4">
            <Form.Check type="checkbox" label="None" />

            <Form.Check type="checkbox" label="Item Level" />

            <Form.Check type="checkbox" label="Cart Level / Order Level" />
          </div>

          <div className="packaginghead">
            <h3>Does your menu have any MRP / Packed Items</h3>
            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-6">
                  <Form.Check type="radio" label="Yes" name="yesno" />
                </div>
                <div className="col-lg-6">
                  <Form.Check type="radio" label="No" name="yesno" />
                </div>
              </div>
            </div>
          </div>

          <div className="row itemDetails">
            <div className="col-lg-6">
              <div className="field-bottom mb-3">
                <Form.Label className="required">Item Details</Form.Label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  classNamePrefix="buisness"
                  isMulti="true"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="field-bottom mb-3">
                <Form.Label className="required">Timing</Form.Label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  classNamePrefix="buisness"
                  isMulti="true"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="field-bottom">
                <Form.Label className="required">Days</Form.Label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  classNamePrefix="buisness"
                  isMulti="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" text-end apply_now_btn">
          <Button onClick={props.prevStep} className="back-btn me-3">
            Back
          </Button>

          {/* <div className="registerContinueMain"> */}
          <Button type="button" onClick={props.nextStep} className="tabs-btn">
            Next
          </Button>

          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Stepeight;
