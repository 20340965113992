import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";
import Select from "react-select";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Stepone = ({ prevStep, nextStep }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "Nashik", label: "Chocolate" },
    { value: "Pune", label: "Strawberry" },
    { value: "Mumbai", label: "Vanilla" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  return (
    <>
      <div className="personal-details-form ">
        {/* form-holder */}

        <div className="details-form-holder">
          <div className="form-container">
            <Form>
              <div className="">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Label className="required">City</Form.Label>
                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        classNamePrefix="buisness"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">Area</Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter Area"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              {/*   Mobile Number , Date of Birth,  Gender*/}
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-field">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">
                          Shop / Showroom Name*
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter Shop/Showroom Name"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required form-field">
                          Owner Contact*
                        </Form.Label>

                        <div className="get-otp-text">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Owner Contact no"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Form className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">
                          Whats App Number*
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="designation"
                          placeholder="Enter Whats App Number"
                          {...register("designation", {
                            required: "designation is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.designation,
                            "is-valid": getValues("designation"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="note">
                    <p>
                      Get Latest upadte on <FontAwesomeIcon icon={faWhatsapp} className="ms-3"/>  WhatsApp
                      <span className="ms-2">
                        <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                      </span>
                    </p>
                    <div className="on-off">
                      <Form>
                        <Form.Check 
                          type="switch"
                          id="custom-switch"
                          label=""
                        />

                      </Form>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="text-end apply_now_btn">
                      <div className="registerContinueMain">
                        <Button
                          className="tabs-btn"
                          type="button"
                          onClick={nextStep}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepone;
