import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "./LogIn.css";
import Forget_Password from "./Forget_Password/Forget_Password";
import Register_Form from "./Register_Form/Register_Form"; // Import Register_Form component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../Common-Component/Common_Button/Common_Button";

function LogIn(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false); // State variable for showing register form

  // Function to handle click event on "Create account?"
  const handleCreateAccountClick = () => {
    setShowRegisterForm(true);
  };

  const buttonStyle = {
    background: props.background,
    borderRadius: props.borderRadius,
    border: props.border,
    fontSize: props.fontSize,
    fontcolor: props.fontcolor,
    color: props.color,
    padding: props.padding,
  };

  return (
    <>
      <div className="LogIn">
        {!showForgetPassword && !showRegisterForm && (
          <div className="row bg-color me-0 ms-0">
            {/* Login Form */}
            <div className="col-md-7 p-0 order-md-1 order-2">
              <div className="row justify-content-center me-0 ms-0  ">
                <div className="col-xl-7  col-md-8  col-sm-6 ">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Login</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <div className="login-with-option-holder mb-2">
                        <Form.Group className="mb-2 login-with-heading" controlId="">
                          <Form.Label >LogIn With</Form.Label>
                        </Form.Group>
                        <Form.Check
                          inline
                          label="Customer"
                          name="group1"
                          type="radio"
                        />
                        <Form.Check
                          inline
                          label="Vender"
                          name="group1"
                          type="radio"
                        />
                      </div>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="phone"
                          placeholder="Email / Mobile Number"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type="password"
                            placeholder="Enter Your Password"
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon="fa-solid fa-eye"
                            />
                          </div>
                        </div>
                        {/* <Link to={"/forget-password"}> */}
                        <Form.Text
                          className="Forgot_password"
                          onClick={() => setShowForgetPassword(true)}
                        >
                          Forgot password?
                        </Form.Text>
                        {/* </Link> */}
                      </Form.Group>

                      <div className="btn-holder">
                        <Link to={"/"}>
                          <Common_Button className="SubmitBtn" text="Login" />
                        </Link>
                      </div>

                      <div className="create_account mb-5">
                        {/* Attach click event handler */}
                        {/* <Link to={"/register-form"}> */}
                        <p onClick={handleCreateAccountClick}>
                          Create account ?
                        </p>
                        {/* </Link> */}
                      </div>
                    </Form>
                    <div className="log_in_with">
                      <p>or login with social accounts</p>
                    </div>

                    <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/log_in/google.png"
                        }
                        alt="social_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Section */}
            <div className="col-md-5 p-0 order-md-2 order-1">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                    }
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showForgetPassword && <Forget_Password />}
        {showRegisterForm && <Register_Form />}{" "}
        {/* Render Register_Form if showRegisterForm is true */}
      </div>
    </>
  );
}

export default LogIn;
