import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";

function Set_New_Password() {
  const [modalShow, setModalShow] = useState(false);

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (2000 milliseconds = 2 seconds)
  };

  return (
    <>
      <div className="LogIn">
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-7 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-lg-6 col-md-8">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>

                <div className="heading-holder">
                  <h4>Set New Password</h4>
                  <p>
                    Your new password must be different to previously used
                    password.
                  </p>
                </div>

                <div className="log_in_form">
                  <Form>
                    <Form.Group className="mb-3" controlId="">
                      <div className="password-field-holder">
                        <Form.Control
                          type="password"
                          placeholder="Enter your password"
                        />
                        <div className="eye-icon-holder ">
                          <FontAwesomeIcon
                            className="eye-icon"
                            icon="fa-solid fa-eye"
                          />
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                      <div className="password-field-holder">
                        <Form.Control
                          type="password"
                          placeholder="Enter your confirm password"
                        />
                        <div className="eye-icon-holder ">
                          <FontAwesomeIcon
                            className="eye-icon"
                            icon="fa-solid fa-eye"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Form>

                  <div className="btn-holder mb-5">
                    <Common_Button
                      onClick={() => {
                        setModalShow(true);
                        hideModalWithDelay(); 
                      }}
                      className="SubmitBtn"
                      text=" Reset Password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 p-0">
            <div className="img-section">
              <div className="img-holder">
                <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                  }
                  alt="log_in_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Successfull_Modal
        message="Password Reset Successfully"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Set_New_Password;
