import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };
  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        {/* <div className="contact_form_holder "> */}
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-4">
                <div
                  className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"
                    }`}
                >
                  <div className="heading-holder mb-4">
                    <h2>Contact us</h2>
                  </div>


                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-phone"
                      />
                    </div>
                    <div className="address-holder">
                      <p>+91 5483580687</p>
                    </div>
                  </div>





                  <a target="_blank" class="webwhats" href="https://web.whatsapp.com/send?phone=9795598526">
                    <div className="d-flex mb-3">
                      <div className="icon-holder me-3">
                        <img className="whtttticc" src={process.env.PUBLIC_URL + '/assets/Image/Icon/whatsapp-icon.png'} />
                      </div>
                      <div className="address-holder">
                        <p>+91 9795598526</p>
                      </div>
                    </div>
                  </a>

                  <a target="_blank" class="mobwhats" href="https://wa.me/9795598526">
                    <div className="d-flex mb-3">                      <div className="icon-holder me-3">
                      <img className="whtttticc" src={process.env.PUBLIC_URL + '/assets/Image/Icon/whatsapp-icon.png'} />
                    </div>
                      <div className="address-holder">
                        <p>+91 9795598526</p>
                      </div>
                    </div>
                  </a>





                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-envelope"
                      />
                    </div>
                    <div className="address-holder">
                      <p>boomerbucks.in@outlook.com</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                    <div className="address-holder">
                      <p>
                      <b>Regional Office</b> <br /> BOOMERBUCKS CORPORATION <br /> Riverbank Road, Ghazipur City. <br /> 233001.  U.P India.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-map-location-dot"
                      />
                    </div>
                    <div
                      className="address-holder map-text"
                      onClick={handleMapTextClick}
                    >
                      <p>Find Us on Map</p>
                    </div>
                  </div>

                  <div className="border-holder  my-4"></div>

                  <div className="social-icon">

                    <Link to={"https://www.facebook.com/people/Boomer-BUCKSin/61556459021580/?mibextid=kFxxJD"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/facebook.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"https://t.me/boomerbucksindia"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/Image/footer/telegram.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"https://x.com/boomerbucks_ind"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/xmark.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"https://www.youtube.com/@boomerbucks"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/youtube.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>

                    <Link to={"https://www.instagram.com/boomerbucks.in/?utm_source=qr&igsh=MTMyOTd5MGx5M201Nw%3D%3D"} target="_blank">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/footer/insta.png"
                          }
                          className="socialIcon"
                        />
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
              <div
                className={`${showForm
                  ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                  : "col-lg-8 col-md-8 scale-up-hor-right"
                  }`}
              >
                <div className="form-holder ">
                  <div
                    className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                      }`}
                    onClick={handleArrowClick}
                  >
                    {/* <FontAwesomeIcon className="left-arrow" icon="fa-solid fa-arrow-left" /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/Icon/arrow-left.png"
                      }
                      className="left-arrow"
                    />
                  </div>
                  <Form>
                    <Row className="form_container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="Your name" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control type="text" placeholder="Mobile No." />
                      </Form.Group>


                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="Subject" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          className="text-holder"
                          placeholder="Your message"
                          rows={3}
                        />
                      </Form.Group>
                      <div className="btn-holder mt-4">
                        <Common_Button
                          className="SubmitBtn"
                          text="Send Message"
                          padding="7px 20px"
                        />
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
