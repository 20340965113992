import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination } from "swiper/modules";
import Coupon_Card from "../../Common-Component/Coupon_Card/Coupon_Card";
import "./My_Coupons.css";

const couponData = [
  {
    codeText: "TRYCODE105",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 5, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE100",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 4, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE100",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 3, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE105",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 2, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE104",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 1, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE108",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 3, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE104",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 2, minutes: 3, seconds: 2 },
  },
  {
    codeText: "TRYCODE100",
    imageUrl: "/assets/Image/My_Profile/coupon_img.png",
    timer: { hours: 4, minutes: 3, seconds: 2 },
  },
];

function My_Coupons() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const [timers, setTimers] = useState(
    couponData.map((coupon) => coupon.timer || null)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prevTimers) =>
        prevTimers.map((timer) => {
          if (!timer) return null;
          const { hours, minutes, seconds } = timer;
          if (hours === 0 && minutes === 0 && seconds === 0) return timer;
          const newSeconds = seconds === 0 ? 59 : seconds - 1;
          const newMinutes =
            seconds === 0 && minutes !== 0 ? minutes - 1 : minutes;
          const newHours =
            minutes === 0 && hours !== 0 ? hours - 1 : hours;
          return {
            hours: newHours,
            minutes: newMinutes,
            seconds: newSeconds,
          };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="Account_Address My_Coupons my-account">
      <div className="container">
        <div className="heading_holder">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/my-account">My account</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>My Coupons</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="">
          <h2 className="title">My Coupons</h2>
        </div>

        <div className="Coupons_holder">
          <Swiper
            slidesPerView={4}
            grid={{ rows: 2 }}
            spaceBetween={30}
            pagination={{ dynamicBullets: true }}
            modules={[Grid, Pagination]}
            className="Coupons_Swiper desktop-swiper mySwiper"
            breakpoints={{
              280: { slidesPerView: 1, spaceBetween: 10 },
              320: { slidesPerView: 1, spaceBetween: 10 },
              480: { slidesPerView: 2, spaceBetween: 10 },
              640: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              992: { slidesPerView: 3, spaceBetween: 10 },
              1024: { slidesPerView: 3, spaceBetween: 10 },
              1200: { slidesPerView: 4, spaceBetween: 10 },
              1400: { slidesPerView: 4, spaceBetween: 10 },
            }}
          >
            {couponData.map((coupon, index) => (
              <SwiperSlide key={index}>
                <Coupon_Card
                  imageUrl={process.env.PUBLIC_URL + coupon.imageUrl}
                  codeText={coupon.codeText}
                  timer={
                    timers[index]
                      ? `${timers[index].hours}h : ${timers[index].minutes}m : ${timers[index].seconds}s`
                      : null
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{ dynamicBullets: true }}
            modules={[Pagination]}
            className="Coupons_Swiper mobile-swiper mySwiper"
            breakpoints={{
              280: { slidesPerView: 1, spaceBetween: 10 },
              320: { slidesPerView: 1, spaceBetween: 10 },
              480: { slidesPerView: 2, spaceBetween: 10 },
              575: { slidesPerView: 2, spaceBetween: 10 },
              640: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              992: { slidesPerView: 3, spaceBetween: 10 },
              1024: { slidesPerView: 3, spaceBetween: 10 },
              1200: { slidesPerView: 4, spaceBetween: 10 },
              1400: { slidesPerView: 4, spaceBetween: 10 },
            }}
          >
            {couponData.map((coupon, index) => (
              <SwiperSlide key={index}>
                <Coupon_Card
                  imageUrl={process.env.PUBLIC_URL + coupon.imageUrl}
                  codeText={coupon.codeText}
                  timer={
                    timers[index]
                      ? `${timers[index].hours}h : ${timers[index].minutes}m : ${timers[index].sechangeconds}s`
                      : null
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default My_Coupons;
