import React, { useRef, useState } from "react";
import "./ProductPage.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heart_Component from "../../../Common-Component/Heart_Component/Heart_Component";
library.add(fas);

const ProductPage = (props) => {
  console.log(props);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const [heartactive, setheartActive] = useState(false);
  const handleClickes = () => {
    setheartActive(!heartactive);
  };

  // Image sources for the Swiper slides
  const imageSources = [
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
    "/assets/Image/Vendor-Page/shoes-small.png",
  ];

  return (
    <>
      <section className="cctv-innerpage">
        <div className="container">
          <div className="row camera-sec">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
              <div className="card-sec ">
                {/* <div className="hearticn">
                  <FontAwesomeIcon
                    icon={faHeart} // Corrected icon usage
                    onClick={handleClickes}
                    className={heartactive ? "gobiheartss" : "gobiheart"}
                  />
                </div> */}

                <Heart_Component/>
                <Link to="/product-detail">
                  <div className="product-detail text-center">
                    {selectedImage ? (
                      <img
                        className="product-img"
                        src={selectedImage}
                        alt="Selected"
                      />
                    ) : (
                      <img
                        className="product-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Vendor-Page/shoes-img-main.png"
                        }
                        alt="Default"
                      />
                    )}
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="camera-slider">
                {/* slider section start */}
                <Swiper
                  slidesPerView={"auto"}
                  modules={[ Autoplay]}
                  spaceBetween={20}
                  breakpoints={{
                    0: { slidesPerView: 3, spaceBetween: 10 },
                    380: { slidesPerView: 3 },
                    485: { slidesPerView: 3 },
                    575: { slidesPerView: 3 },
                    768: { slidesPerView: 5 },
                    992: { slidesPerView: 3 },
                    1024: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                    1440: { slidesPerView: 4 },
                    2000: { slidesPerView: 4 },
                  }}
                  navigation
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    loop: true,
                  }}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {imageSources.map((imageSrc, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="camera-imgs"
                        onClick={() =>
                          handleImageClick(process.env.PUBLIC_URL + imageSrc)
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + imageSrc}
                          className="inner-img"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div
                  className="main-slider-cctv"
                  onClick={() => handleMainSliderClick("prev")}
                >
                  <div className="arrow-back" />
                </div>
                <div
                  className="main-slider-cctv"
                  onClick={() => handleMainSliderClick("next")}
                >
                  <div className="arrow-next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductPage;
