import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import FirstVendorDetails from "./FirstVendorDetails/FirstVendorDetails";
import "../FirstVendor/FirstVendorDetails/FirstVendorDetails.css";
import ProductPage from "./ProductPage/ProductPage";
import { Link, useNavigate } from "react-router-dom";
const FirstVendor = () => {
  return (
    <section className="first-vendor">
      <Container>
        <div className="bred-cumb-vendor">
          <Breadcrumb className="mt-5">
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/product"> Fruits & Vegetables</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Cabbage 1 Pc (Approx. 600 -1000 gm)
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row>
          <Col xl={4} lg={4} md={12} sm={12}>
            <ProductPage />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12}>
            <FirstVendorDetails />
          </Col>
        </Row>
        <hr />
      </Container>
    </section>
  );
};

export default FirstVendor;
