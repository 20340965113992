import React, { useEffect } from 'react'
import './Career.css'
import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Career = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    const career = [
        {
            time: 'full',
            icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
            position: 'Content Writer',
            address: 'Bavdhan,Pune',
            type: 'Hybrid',
            experience: '1-3 Years'
        },
        {
            time: 'full',
            icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
            position: 'Content Writer',
            address: 'Bavdhan,Pune',
            type: 'Hybrid',
            experience: '1-3 Years'
        },
        {
            time: 'full',
            icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
            position: 'Content Writer',
            address: 'Bavdhan,Pune',
            type: 'Hybrid',
            experience: '1-3 Years'
        },
        {
            time: 'full',
            icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
            position: 'Content Writer',
            address: 'Bavdhan,Pune',
            type: 'Hybrid',
            experience: '1-3 Years'
        },
    ];


    return (
        <>
            <section className='careermain-sec All-boom-content'>
                <div className='container'>

                <img  className='bannerr' src={process.env.PUBLIC_URL + '/assets/Image/earn/Bommerbucks-career.jpg'} />
                    
                    <div className=' text-center'>
                        <h3>Make Your career with Boomerbucks</h3>
                    </div>

                    <div className='row mt-5'>
                        {career.map((data, index) => (
                            <div className='col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='career-card'>
                                    <div className='top-div'>
                                        <p className='jobtime'>{data.time} time</p>

                                        <div className='job-details'>
                                            <div className='icon-div'>
                                                <FontAwesomeIcon className='iconn' icon="fa-solid fa-pen-to-square" />
                                            </div>
                                            <h6 className='pos-name'>{data.position}</h6>

                                            <p className='add-p'><FontAwesomeIcon className='addicc' icon="fa-solid fa-location-dot" /> {data.address} </p>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className=''>
                                            <p className='exppp'><FontAwesomeIcon icon="fa-solid fa-graduation-cap" /> {data.experience} </p>
                                        </div>

                                        <div>
                                            <Link className='applybtn' to={"/career-description"}>View & Apply</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career