import React, { useState } from "react";
import "./OurProduct.css";
import { Card, Col, CardFooter, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
library.add(fas);
const products = [
  {
    name: "Nescafe ",
    subText: "Classic Instant Coffee...",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/1p.png",
  },
  {
    name: "Kurkure ",
    subText: "Toofaani Mirch Chips",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/2p.png",
  },
  {
    name: "Beet Root ",
    subText: "1 KG",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/3p.png",
  },
  {
    name: "Himalaya ",
    subText: "Moisturizing Body Lotion",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/4p.png",
  },
  {
    name: "Huggies ",
    subText: "Wonder Pants",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/5p.png",
  },
  {
    name: "Dettol ",
    subText: "Antiseptic Liquid",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/6p.png",
  },
  {
    name: "Thums Up ",
    subText: "Thums Up 1 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/7p.png",
  },
  {
    name: "Nescafe ",
    subText: "Classic Instant Coffee...",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Our-Product/1p.png",
  },
];

const OurProduct = () => {
  const [showAll, setShowAll] = useState(false);
  const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      // If currently showing all, reduce the number of rows displayed
      setNumToShow(4);
    } else {
      // If currently not showing all, show all rows
      setNumToShow(products.length);
    }
  };
  return (
    <section className="our-product">
      <Container>
        <div className=" mb-4 mt-5">
          <h2 className="title">All Products</h2>
        </div>

        {/* desktop_view_product_category_slider start */}
        <div className="desktop_view_product_category_slider">
          <Row>
            {/* {visibleProducts.map((product, index) => ( */}

            {products.slice(0, numToShow).map((product, index) => (

              // index === 0 ? (
              //   <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
              //     <div className="birthday-cardd mt-4 mb-5">
              //       <div className="img-div">
              //         <img className="cakeimg" src={process.env.PUBLIC_URL + '/assets/Image/Homepage/cake/cake2.jpg'} />
              //       </div>
              //       <div className="wishes-div">
              //         <h3 className="hbdd">Happy Birhday Aditya</h3>

              //         <p className="wishes">“May the joy that you have spread in the past come back to you on this day. Wishing you a very happy birthday!”  </p>
              //       </div>
              //     </div>
              //   </Col>
              // ) : (
              //   <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
              //     <Link to={"/vendor-page"}>

              //       <Product_Card
              //         image={product.image}
              //         text={product.name}
              //         subText={product.subText}
              //         price={product.price}
              //       />


              //     </Link>
              //   </Col>
              // )


              <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                <Link to={"/vendor-page"}>

                  <Product_Card
                    image={product.image}
                    text={product.name}
                    subText={product.subText}
                    price={product.price}
                  />


                </Link>
              </Col>



            ))}

          </Row>
        </div>

        {/* desktop_view_product_category_slider end */}

        {/* mobile_view_product_category_slider start */}

        <div className="mobile_view_product_category_slider">
          <Row className="ms-0 me-0">
            <Swiper
              className="our-product-slider mySwiper"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                420: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                575: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              {products.slice(0, numToShow).map((product, index) => (

                <SwiperSlide>
                  <Link to={"/vendor-page"}>
                    <Product_Card
                      image={product.image}
                      text={product.name}
                      subText={product.subText}
                      price={product.price}
                    />
                  </Link>
                </SwiperSlide>



              ))}
            </Swiper>
          </Row>
        </div>

        {/* mobile_view_product_category_slider end */}

        {
          showAll ? (
            <div className="view-all mt-4" onClick={toggleShowAll}>
              <p>View Less</p>
            </div>
          ) : (
            <div className="view-all mt-4" onClick={toggleShowAll}>
              <p>View All</p>
            </div>
          )
        }
      </Container>
    </section>
  );
};

export default OurProduct;
