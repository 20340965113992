import React from "react";
import "./Membership_card.css";
import Common_Button from "../Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faStar } from "@fortawesome/free-solid-svg-icons";
import Membership_card_modal from "../../Common_modal/Membership_card_modal/Membership_card_modal";

function Membership_card({btnText, headingText , amountText}) {
  const [modalShow, setModalShow] = React.useState(false);

  const benefits = [

    { icon: faCheck, text: "Early access to deals.", isCheck: true },
    { icon: faCheck, text: "Unlock exclusive member deals.", isCheck: true },
    { icon: faXmark, text: "Celebrate your birthday perks!", isCheck: false },
    { icon: faXmark, text: "Get VIP customer support.", isCheck: false },
    { icon: faXmark, text: "Unlock extra discounts selectively", isCheck: false },
    { icon: faXmark, text: "Early access to deals.", isCheck: false },
    { icon: faXmark, text: "Unlock top discounts everywhere.", isCheck: false },
    { icon: faXmark, text: "Always enjoy free shipping.", isCheck: false },
    { icon: faXmark, text: "Come to exclusive VIP events.", isCheck: false },
    { icon: faXmark, text: "Flexible return and exchange.", isCheck: false },
    { icon: faStar, text: "Rs.3000  Minimum amount spend", isMainText: true }

  ];

  return (
    <>
      <section className="Membership_card">
        <div className="card">
          <div className="card-holder">
            <div className="card-header">
              <div className="heading-holder">
                <h3>{headingText}</h3>
              </div>
            </div>
            <div className="card-body">
              <div className="list-holder">
                <ul>
                  {benefits.map((benefit, index) => (
                    <li key={index} className={benefit.isMainText ? "main-text" : ""}>
                      <FontAwesomeIcon
                        className={benefit.isCheck ? "check-icon" : (benefit.isMainText ? "star-icon" : "cross-icon")}
                        icon={benefit.icon}
                      />{" "}                      
                      { benefit.text}
                    </li>
                  ))}
                </ul>

                
              </div>
            </div>

            <div className="card-footer">
              <div className="btn-holder">
                <Common_Button
                  className={"View_More_Btn"}
                  text={btnText}
                  onClick={() => setModalShow(true)}
                />
              </div>
            </div>


          </div>
        </div>
      </section>
      <Membership_card_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Membership_card;
