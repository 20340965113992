import React, { useEffect, useState } from "react";
import "./Product_Cart.css";
import {
  Breadcrumb,
  Button,
  Form,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";

function Product_Cart() {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [numberOne, setNumberOne] = useState(1);
  const [numberTwo, setNumberTwo] = useState(1);

  const handleMinusClickOne = () => {
    if (numberOne > 1) {
      setNumberOne(numberOne - 1);
    }
  };

  const handlePlusClickOne = () => {
    setNumberOne(numberOne + 1);
  };

  const handleMinusClickTwo = () => {
    if (numberTwo > 1) {
      setNumberTwo(numberTwo - 1);
    }
  };

  const handlePlusClickTwo = () => {
    setNumberTwo(numberTwo + 1);
  };
  return (
    <>
      <div className="Product_Cart ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/product-cart"}>Cart</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="row">
            <div className="col-xxl-9 col-xl-9 col-lg-8">
              <div className="product_table">
                {/* <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Image</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Tax</th>
                      <th>MRP</th>
                      <th>Order Price</th>
                      <th>Tax</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="Product_Cart_img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/Product_Cart/Cabbage.png"
                            }
                            className="cart_img"
                          />
                        </div>
                      </td>
                      <td>Cabbage</td>
                      <td>
                        <div className="blue-bg d-flex ">
                          <div className="circle-holder1">
                            <button
                              className="minus-btn me-md-5 me-0"
                              type="button"
                              onClick={handleMinusClickOne}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </button>
                          </div>
                          <p className="number-text  ">{numberOne}</p>
                          <div className="circle-holder1">
                            <button
                              className="minus-btn"
                              type="button"
                              onClick={handlePlusClickOne}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>12%</td>
                      <td>₹30</td>
                      <td>₹30</td>
                      <td>₹5</td>
                      <td>₹35</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <div className="Product_Cart_img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Image/Product_Cart/Tomato.png"
                            }
                            className="cart_img"
                          />
                        </div>
                      </td>
                      <td>Tomato</td>
                      <td>
                        <div className="blue-bg d-flex ">
                          <div className="circle-holder1">
                            <button
                              className="minus-btn me-md-5 me-0"
                              type="button"
                              onClick={handleMinusClickTwo}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </button>
                          </div>
                          <p className="number-text  ">{numberTwo}</p>
                          <div className="circle-holder1">
                            <button
                              className="minus-btn"
                              type="button"
                              onClick={handlePlusClickTwo}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>12%</td>
                      <td>₹50</td>
                      <td>₹50</td>
                      <td>₹5</td>
                      <td>₹55</td>
                    </tr>
                  </tbody>
                </Table> */}

                <div className="row tabless mb-4">
                  <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-2">
                    <div className="cabbageimg">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Product_Cart/Cabbage.png"
                        }
                        className="cartimg"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-4">
                    <div className="prices">
                      <p className="cabbage">Tomato</p>
                      <p className="prce me-2">
                        Price : <span>₹30</span>
                      </p>
                      <p className="prce me-2">
                        Tax : <span>12%</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-5">
                    <div className="prices">
                      <p className="cabbage cabbage2">Cabbage</p>
                      <p className="prce">
                        Order Price: <span>₹30</span>
                      </p>
                      <p className="prce">
                        Tax : <span>₹5</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                    <div className="prices">
                      <p className="cabbage cabbage2">Cabbage</p>
                      <p className="prce">
                        Total Price: <span>₹35</span>
                      </p>
                      <div className="qnty">
                        <p className="prce mb-0">Quantity:</p>
                        <div className="counting">
                          <button
                            className="count-btn"
                            type="button"
                            onClick={handleMinusClickTwo}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-minus"
                              className="minnusicns"
                            />
                          </button>
                          <p className=" prce nombers">{numberTwo}</p>
                          <button
                            className="count-btn"
                            type="button"
                            onClick={handlePlusClickTwo}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-plus"
                              className="plusicns"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5  trash-icon-holder">
                    <div className="prices">
                    <p className="cabbage cabbage2"></p>
                      <p className="prce">
                        <span></span>
                      </p>
                      <button
                        className="count-btn trash-icon_btn"
                        type="button"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          className=" trash-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row tabless mb-3">
                  <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-2">
                    <div className="cabbageimg">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Product_Cart/Tomato.png"
                        }
                        className="cartimg"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-4">
                    <div className="prices">
                      <p className="cabbage">Tomato</p>
                      <p className="prce me-2">
                        Price : <span>₹30</span>
                      </p>
                      <p className="prce me-2">
                        Tax : <span>12%</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-5">
                    <div className="prices">
                      <p className="cabbage cabbage2">Cabbage</p>
                      <p className="prce">
                        Order Price: <span>₹30</span>
                      </p>
                      <p className="prce">
                        Tax : <span>₹5</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                    <div className="prices">
                      <p className="cabbage cabbage2">Cabbage</p>
                      <p className="prce">
                        Total Price: <span>₹35</span>
                      </p>
                      <div className="qnty">
                        <p className="prce mb-0">Quantity:</p>
                        <div className="counting">
                          <button
                            className="count-btn"
                            type="button"
                            onClick={handleMinusClickTwo}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-minus"
                              className="minnusicns"
                            />
                          </button>
                          <p className=" prce nombers">{numberTwo}</p>
                          <button
                            className="count-btn"
                            type="button"
                            onClick={handlePlusClickTwo}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-plus"
                              className="plusicns"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5  trash-icon-holder">
                    <div className="prices">
                    <p className="cabbage cabbage2"></p>
                      <p className="prce">
                        <span></span>
                      </p>
                      <button
                        className="count-btn trash-icon_btn"
                        type="button"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          className=" trash-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={"/"}>
                <div className="Continue_Shopping_text">
                  <p>Continue Shopping</p>
                </div>
              </Link>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-4">
              <div className="grand_total_table">
                <div className="orderss">
                  <h6 className="ordersum">Order Summary</h6>
                </div>
                <Table responsive>
                  <thead>
                    <tr className="top-border">
                      <th>Total amount</th>
                      <td>₹90</td>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="top-border">
                      <th>Discount</th>
                      <td>₹0</td>
                    </tr>
                  </thead>

                  <thead>
                    <tr className="top-border">
                      <th>Tax</th>
                      <td>₹0</td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Shipping</th>
                      <td>₹0</td>
                    </tr>
                  </thead>

                  <thead>
                    <tr className="Coupon_code_holder top-border">
                      <th className="Coupon_code">
                        <Form>
                          <Form.Control type="text" placeholder="Coupon code" />
                        </Form>
                      </th>
                      <td>
                        <div className="button-holder">
                          <Button className="Checkout_btn" type="button">
                            Apply Coupon
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </thead>

                  <thead>
                    <tr className="bottom-border">
                      <th className="bold-text">Total</th>
                      <td className="bold-text">₹90</td>
                    </tr>
                  </thead>
                </Table>
              </div>

              <div className="check-box-text-holder">
                <Form className="">
                  <div className="check-box-holder-info">
                    <Form.Check
                      label="Quick Delivery"
                      name="Quick_Delivery"
                      type="checkbox"
                      className="me-1 my-2"
                    />

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-quick-delivery">
                          Quick Delivery
                        </Tooltip>
                      }
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="info-icon"
                      />
                    </OverlayTrigger>
                  </div>

                  <div className="check-box-holder-info">
                    <Form.Check
                      label="Open Delivery"
                      name="Quick_Delivery"
                      type="checkbox"
                      className="me-1 my-2"
                    />

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-open-delivery">
                          Open Delivery
                        </Tooltip>
                      }
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="info-icon"
                      />
                    </OverlayTrigger>
                  </div>
                </Form>
              </div>
              <div className="button-holder">
                <Link to={"/product-cart-details"}>
                  <Button className="procheckout_btn" type="button">
                    Proceed to Checkout
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Item deleted succsessfully"}
      />
    </>
  );
}

export default Product_Cart;
