import React, { useState } from "react";
import "./FirstVendorDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import {
  faCartShopping,
  faHeart as solidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Variants_Holder from "../../../Common-Component/Variants_Holder/Variants_Holder";

const FirstVendorDetails = () => {
  const [number, setNumber] = useState(1);
  const [showButtons, setShowButtons] = useState(false);
  const [showHeart, setShowHeart] = useState(false); // State for heart icon

  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const handleHeartClick = () => {
    setShowHeart(!showHeart); // Toggle the heart icon state
    setShow(false); // Hide the section with blue background and buttons
  };
  const [show, setShow] = useState(false);

  return (
    <section className="first-vendor-details">
      <div className="title-details mt-5">
        <h2>Cabbage 1 Pc (Approx. 600 -1000 gm)</h2>
        <p className="min-order-text">Minimum order quantity : <span className="qty-text">25000 Pieces</span></p>
        <p className="mrp-class">
          MRP. ₹. 00.00 <span>(Incl. of all taxes)</span>
        </p>
        <p className="return-mrp">₹. 00.00</p>
        <p className="in-stock">In Stock</p>
      </div>
      <div className="button-effect">
        <Common_Button
          onClick={() => setShow(!show)}
          className="add-to-cart-btn me-3"
          icon={faCartShopping} // Pass the icon as prop
          text=" Add to Cart"
        />

        <Link to="/product-cart">
          {" "}
          <Common_Button
            className="add-to-cart-btn"
            text=" Buy Now"
            icon="fa-solid fa-cart-shopping"
          />
        </Link>

        {/* <div className="qntybtns d-flex">
          <div className="holder-circle1">
            <button
              className="pluss-btn min-btns me-0"
              type="button"
              onClick={handleMinusClick}
            >
              <FontAwesomeIcon icon="fa-solid fa-minus" />
            </button>
          </div>
          <div className="cardcounts">
            <p className="numtext  mb-0">{number}</p>
          </div>
          <div className="holder-circle1">
            <button
              className="pluss-btn"
              type="button"
              onClick={handlePlusClick}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          </div>
        </div> */}

        {show && (
          <div className="blue-bg d-flex mt-2">
            <div className="circle-holder1">
              <button
                className="minus-btn me-md-5 me-0"
                type="button"
                onClick={handleMinusClick}
              >
                <FontAwesomeIcon
                  className="minus-icon"
                  icon="fa-solid fa-minus"
                />
              </button>
            </div>
            <p className="number-text ">{number}</p>
            <div className="circle-holder1">
              <button
                className="minus-btn"
                type="button"
                onClick={handlePlusClick}
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* After Onlclick */}

      <div className="variant-holder">
        <div className="heading-holder">
          <h3>Select variant</h3>
        </div>
        <div className="variant-color-div">
          <div className="variant-heading-holder">
            <h2>Color</h2>
          </div>

          <div className="color-variant-holder">
            <div className="variants">
              <div className="">
                <Variants_Holder
                  background={"#127dbe"}
                  borderColor={"#127dbe"}
                />
              </div>

              <div className="">
                <Variants_Holder background={"red"} borderColor={"red"} />
              </div>

              <div className="">
                <Variants_Holder background={"yellow"} borderColor={"yellow"} />
              </div>
            </div>
          </div>
        </div>

        <div className="variant-color-div">
          <div className="variant-heading-holder">
            <h2>Size</h2>
          </div>

          <div className="Size-variant-holder">
            <div className="Size">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="size-btn">
                      <Nav.Item>
                        <Nav.Link eventKey="first">8 UK</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="second">9 UK</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="third">10 UK</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="fourth">11 UK</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>

      <div className="description-class mt-4 mb-4">
        <h2 className="description-class-title">Description</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="first">About the Product</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Care Instructions</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="first">
              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Color</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>Blue</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Inner material</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>FLYKNIT/MESH</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Outer material</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>Mesh</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Model name</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>ARIZONA-N</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Model name</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>ARIZONA-N</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Ideal for</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>Men</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Secondary color</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>Green</p>
                  </div>
                </div>
              </div>

              <div className="text-holder-vendor-page">
                <div className="row">
                  <div className="col-md-3 col-3">
                    <span>Weight</span>
                  </div>
                  <div className="col-md-9 col-9">
                    <p>600 g (per single Shoe) - Weight of the product may vary depending on size.</p>
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <p className="text-holder-vendor-page">
                Allow your pair of shoes to air and de-odorize at a regular
                basis, this also helps them retain their natural shape; use shoe
                bags to prevent any stains or mildew; dust any dry dirt from the
                surface using a clean cloth, do not use polish or shiner
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <p className="text-holder-vendor-page">
                Do not cut cabbages unless you are immediately consuming as it
                begins to lose vitamin C when cut. If you absolutely cannot
                immediately finish the remaining cabbage, wrap it tightly in
                plastic wrap and store it in the refrigerator for up to two
                days. Keeping them in the refrigerator retains the crispiness of
                the vegetable. Shredded cabbage can be directly added to any
                salad and sandwiches as they are most nutritious when eaten raw.
                Also used in pickles and flatbread.
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="four">
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Brand:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">Private Lable</p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Manufaturer:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">Private Lable</p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Vendor:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">E Cart Grocery Seller</p>
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  <p className="brod-title">Sold by:</p>
                </Col>
                <Col xl={9}>
                  <p className="brod-subtitle">Boomer Bucks</p>
                </Col>
              </Row>
              <div className="orange-bg-details">
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">
                      Boomer Bucks Customer Care Email:
                    </p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">Boomer Bucks</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">
                      Boomer Bucks Customer Care Phone:{" "}
                    </p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">0000 000 0000</p>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <p className="brod-title-2">Country of Origin: </p>
                  </Col>
                  <Col xl={6}>
                    <p className="brod-subtitle-2">India</p>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

export default FirstVendorDetails;
