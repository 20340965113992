import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";

library.add(fas);

function Steptwo(props) {
  const [fieldclick, setFieldclick] = useState(false);
  const handleFields = () => {
    setFieldclick(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();




  return (
    <>
      <div className="personal-details-form userForm">
        {/* form-holder */}
        <div className="details-form-holder">
          <div className="row">
            <Form >
              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">

                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">Name of Certification</Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter Certification"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">License</Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter License"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              {/*   Mobile Number , Date of Birth,  Gender*/}
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-field">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">License Expiry Date</Form.Label>

                        <Form.Control
                          type="text"
                          name="Last_Name"
                          placeholder="Enter License Expiry Date"
                          {...register("l_name", {
                            required: "Last name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.l_name,
                            "is-valid": getValues("l_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="required form-field">
                            Enter Registration Number
                          </Form.Label>
                          <p className="mb-0 validate" onClick={handleFields}>Validate</p>

                        </div>

                        <div className="get-otp-text">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="88888-88"

                          />

                        </div>




                      </Form.Group>
                    </div>
                  </div>

                  {fieldclick && (
                    <>
                      <div className="col-md-6">
                        <div className="field-bottom">
                          <Form.Group controlId="Last_Name">
                            <Form.Label className="required form-field">
                              License Type
                            </Form.Label>

                            <div className="get-otp-text">
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="License Type"

                              />

                            </div>




                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-bottom">
                          <Form.Group controlId="Last_Name">

                            <Form.Label className="required form-field">
                              Name on License*
                            </Form.Label>
                            <div className="get-otp-text">
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="John Doe"

                              />

                            </div>




                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-bottom">
                          <Form.Group controlId="Last_Name">

                            <Form.Label className="required form-field">
                              Address in License
                            </Form.Label>
                            <div className="get-otp-text">
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703"

                              />

                            </div>




                          </Form.Group>
                        </div>
                      </div>

                    </>
                  )}



                  <div className="col-md-12">
                    <div className=" text-end apply_now_btn">


                      <Button onClick={props.prevStep} className="back-btn me-3">
                        Back
                      </Button>


                      <Button type="button" onClick={props.nextStep} className="tabs-btn">
                        Next
                      </Button>


                    </div>


                  </div>
                </div>
              </div>
            </Form>
          </div>


        </div>
      </div>
    </>
  );
}

export default Steptwo;
