import React, { useState } from "react";
import "./ScanMartStore.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";
import TrandBee from "./TrandBee";
import { Link } from "react-router-dom";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Online_Store from "../../Common-Component/Online_Store/Online_Store";
const ScanMartStore = () => {
  const [rating, setRating] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [likedProducts, setLikedProducts] = useState([]); // State to track liked products

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleReset = () => {
    // Set the initial value
    setRating(0);
  };

  const handleLike = (index) => {
    setLikedProducts((prevLikedProducts) => {
      const updatedLikedProducts = [...prevLikedProducts];
      updatedLikedProducts[index] = !updatedLikedProducts[index];
      return updatedLikedProducts;
    });
  };
  const StoreDetails = [
    {
      id: 1,
      productname: "Lenovo ",
      color: "IdeaPad Slim 1",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/Computer.png",
      detailsLink: "/vendor-page",
    },
    {
      productname: "LG ",
      color: "6.5 Kg 5 Star Inverter Direct Drive Fully",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image:
        process.env.PUBLIC_URL + "/assets/Image/near-me/washing_machine.png",
    },
    {
      productname: "Samsung ",
      color: "28L, Convection Microwave Oven with Curd Making",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/microweb.png",
    },
    {
      productname: "Apple ",
      color: "iPhone 13 (128GB) - Midnight",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/phone.png",
    },
    {
      productname: "Samsung ",
      color: "Galaxy Tab S6 Lite 26.31 cm",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/tablet.png",
    },
  ];
  return (
    <>
      <section className="near-mart-store">
        <div className="container">
          <div className="heading-holder">
            <h1>Near by shops</h1>
          </div>
          <div className="slider-sec">
            <div className="row">
              <div className="col-lg-3 col-md-4 mt-5">
                {/* <Online_Store/> */}

                <Online_Store
                  rating={rating}
                  handleRating={handleRating}
                  image="/assets/Image/near-me/online-store.png"
                  heading="ScanMart Store"
                  city="Pune"
                  distanceLocation="5 minutes away from your location"
                />
              </div>
              <div className="col-lg-9 col-md-8 mt-5">
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    420: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2.5,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 2.5,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1400: {
                      slidesPerView: 4.5,
                      spaceBetween: 10,
                    },
                    1900: {
                      slidesPerView: 4.5,
                      spaceBetween: 10,
                    },
                  }}
                  navigation={true}
                  modules={[Navigation, Pagination]}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {StoreDetails.map((item, index) => (
                    <SwiperSlide key={`${index}-${item.detailsLink}`}>
                      <Veggies_Section_Card
                        detailsLink={item.detailsLink}
                        image={item.image}
                        shoesName={item.productname}
                        color={item.color}
                        price={item.price}
                        offprice={item.offprice}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div class="silder-btn">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  ></div>
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-sec">
            <TrandBee />
          </div>

          <div className="slider-sec">
            <TrandBee />
          </div>
        </div>
      </section>
    </>
  );
};

export default ScanMartStore;
