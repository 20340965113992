import React, { useState } from "react";
import "./Information.css";
import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Information() {
  const [startDate, setStartDate] = useState(null); // State to manage date
  const [modalShow, setModalShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
  const [profileImage, setProfileImage] = useState(
    process.env.PUBLIC_URL + "/assets/Image/My_Profile/swapnil.png"
  ); // State to manage profile image

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/account-information"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };

  return (
    <>
      <section className="account-information my-account ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/information"}>Account Information </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/my-account"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Account Information</h2>
          </div>

          <div className="form-section">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 mb-3">
                <div className="account-form">
                  <div className="row mb-3">
                    <div className="col-md-4 col-10">
                      <h4 className="form-title">Account Details</h4>
                    </div>

                    <div className="col-md-4 text-center">
                      <div className="profile-img-icon-holder">
                        <div className="img-holder">
                          <img className="user-prof-img" src={profileImage} />
                        </div>
                        <div className="plus-icon-holder">
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id="file-input"
                            onChange={handleImageChange}
                          />
                          <label htmlFor="file-input">
                            <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-2 text-end">
                      <Link to={"#"} onClick={() => setIsEditMode(true)}>
                        <h6 className="edit">Edit</h6>
                      </Link>
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control type="text" placeholder="Enter Name" />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Address*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          rows={2}
                          as="textarea"
                          type="gender"
                          placeholder="Enter Address "
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Mobile Number*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="phone-number"
                          placeholder="Enter Mobile Number"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Email
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="Enter Email Id"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Date Of Birth*
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="DD/MM/YYYY"
                        />
                      </Col>
                    </Form.Group>

                    {isEditMode && (
                      <div className="btn-holder text-center">
                        <Common_Button
                          className={"SubmitBtn"}
                          text={"Update"}
                          onClick={() => {
                            setModalShow(true);
                            hideModalWithDelay();
                          }}
                        />
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Successfull_Modal
        message="Account Details Updated Successfully"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Information;
