import React, { useEffect } from 'react'
import './career-description.css'
import Form from 'react-bootstrap/Form';

const Career_description = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);


    return (
        <>
            <section className='Career_description'>
                <div className='container'>
                
                    
                    <div className='title-div text-center'>
                        <h3>Job Description</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='sec-1'>
                                <ul>
                                    <li>Work Module : Hybrid</li>
                                    <li>Job Type : Full Time</li>
                                    <li>Job Location : Bavdhan , Pune</li>
                                    <li>Department : Content</li>
                                    <li>Experience : 1-3 Years</li>
                                    <li>Qualification : Graduate/post-graduate in Mass Communication, English Literature, and Journalism</li>
                                </ul>


                                <div className='rem-cont'>
                                    <h5>Job Responsibilities:</h5>
                                    <ul>
                                        <li>Edit market research reports in accordance with business language</li>
                                        <li>Rephrase or paraphrase the content to bring in better clarity</li>
                                        <li>Discuss ambiguous and convoluted information with in-house writers and produce logically structured content</li>
                                        <li>Eliminate pompous frills and identify nonsensical words or phrases</li>
                                        <li>Ensure on-time delivery of reports, articles, marketing collaterals, etc.</li>
                                        <li>Provide effective and constructive feedback to the writers</li>
                                    </ul>


                                    <h5>Requirements:</h5>
                                    <ul>
                                        <li>Proficiency in English language</li>
                                        <li>Preferably a graduate/post-graduate in Mass Communication, English Literature, and Journalism</li>
                                        <li>Proven experience working under pressure to deliver high-quality output in time</li>
                                        <li>Familiarity with MS Office</li>
                                        <li>Willingness to multitask and take up new initiatives</li>
                                        <li>Attention to detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='sec-2'>
                                <div className='text-center mb-4'>
                                    <h5>Application Form</h5>
                                </div>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Canditate Name"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Contact number"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Email"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Current Location"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Current Organisation"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Current CTC"                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>                                  
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Total Exp."                                       
                                    />
                                </Form.Group>
                                <Form.Group className='form-group'>
                                    <Form.Label>Upload Resume : * only PDF & max size 2MB</Form.Label>                                  
                                    <Form.Control
                                        required
                                        type="file"
                                        placeholder=""                                       
                                    />
                                </Form.Group>

                                <button className='proceed-bttn'>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career_description