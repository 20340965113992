import React, { useEffect } from "react";
import "./wallet.css";
import { Link } from "react-router-dom";
import CoupenCard from "./coupen-card/CoupenCard";
const Wallet = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <section className="wallet desk-top-view-wallet ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Wallet</li>
                </ol>
              </nav>
            </div>
            <div className="col-6">
              <Link to="/wallet-transaction">
                <div className=" text-end my-3 ">
                  <button className="btn btn-history">wallet History</button>
                </div>
              </Link>
            </div>
          </div>

          <div className="row me-0 ms-0 justify-content-center">
            <div className="start-wallet-coupen">
              <div className="col-lg-4 col-md-6 mx-auto">
                <div className="input-group mb-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter amount"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <Link to="/wallet-payment">
                    {" "}
                    <span className="input-group-text" id="basic-addon2">
                      Proceed
                    </span>
                  </Link>
                </div>
              </div>
              <div className="apply-coupen text-center">
                <h4>Apply Coupon</h4>
                <p>
                  Take advantage of the coupon and receive a discount enter
                  coupon code below
                </p>
              </div>
              <div className="col-lg-4 col-md-6 mx-auto">
                <div className="input-group my-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter coupon code"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <span className="input-group-text" id="basic-addon2">
                    Apply
                  </span>
                </div>
              </div>
              <div className="apply-coupen text-center mb-5">
                <h4>or choose from below</h4>
              </div>
            </div>
            <div className="wallet-coupon-card-holder">
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <CoupenCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='wallet mobile-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb my-3">
                                    <li className="breadcrumb-item"><Link to='/'>Home </Link></li>
                                    <li className="breadcrumb-item active">Wallet</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-6'>
                            <Link to='/wallet-transaction'>
                                <div className=' text-end my-3 '>
                                    <button className='btn btn-history'>wallet History</button>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='start-wallet-coupen'>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group mb-4">
                                    <input type="text" className="form-control" placeholder="Enter amount" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <Link to='/wallet-payment'>  <span className="input-group-text ps-3" id="basic-addon2">Proceed</span></Link>
                                </div>
                            </div>
                            <div className='apply-coupen text-center'>
                                <h4>Apply Coupon</h4>
                                <p>Take advantage of the coupon and receive a discount enter coupon code below</p>
                            </div>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group mt-3">
                                    <input type="text" className="form-control" placeholder="Enter coupon code" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <span className="input-group-text ps-4" id="basic-addon2">Apply</span>
                                </div>
                            </div>
                            <div className='apply-coupen text-center mt-1 mb-5'>
                                <h4>or choose from below</h4>
                            </div>
                        </div>


                        <div className="col-sm-6 col-6  mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6  mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>

                    </div>

                </div>
            </section> */}
    </>
  );
};

export default Wallet;
