import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./Membership_card_modal.css";
import Membership_card from "../../Common-Component/Membership_card/Membership_card";
import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";

function Membership_card_modal(props) {
  return (
    <>
      <div className="Successfull_Modal_sec ">
        <Modal
          className="Successfull_Modal Membership_card_modal"
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Membership__Modal_card headingText={"Silver_Membership"} btnText={"View Less"} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Membership_card_modal;
