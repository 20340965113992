import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { RegxExpression } from "../../../../utils/common";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
function Stepsix(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [verified, setVerified] = useState(false);
  const verfiedClick = () => {
    setVerified(true)
  }
  const optionsnew = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  const uploader = Uploader({
    apiKey: "free"
  });


  const options = { multi: true };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  return (
    <>

      <section className="personal-details-form userForm">
        <div className="details-form-holder">
          <div className="form-container">
            <Form >

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="field-bottom">
                    <div className="">
                      <Form.Label className="required">Enter Account Number*</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Account Number"

                      />

                    </div>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <div className="d-flex justify-content-between">
                      <Form.Label className="required">IFSC Code*</Form.Label>
                      <p className="required mb-0" style={{ color: "#DF4223" }} onClick={verfiedClick}>Verify</p>

                    </div>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter IFSC Code"

                    />
                  </div>
                </div>


                {verified && (
                  <>
                    <div className="col-md-6">
                      <div className="field-bottom">
                        <Form.Label className="required">Beneficiary Name*</Form.Label>

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Beneficiary Name"

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field-bottom">
                        <Form.Label className="required">Bank City*</Form.Label>

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Bank City"

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field-bottom">
                        <Form.Label className="required">Bank State*</Form.Label>

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Bank State"

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field-bottom">
                        <Form.Label className="required">Bank Name*</Form.Label>

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Bank name"

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field-bottom">
                        <Form.Label className="required">Bank Branch</Form.Label>

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Branch Name"

                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <Form className="field-bottom">
                    <Form.Group controlId="Last_Name" >
                      <Form.Label className="required">
                        Upload Cancelled Cheque/ Bank Passbook 1st Pg/Bank Statment with bank details type
                      </Form.Label>

                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Check
                            type="radio"
                            label="Image"
                            id="Image-1"
                            name="name-1"
                          />

                        </div>
                        <div className="col-lg-6">
                          <Form.Check
                            type="radio"
                            label="Document"
                            id="Image-1"
                            name="name-1"
                          />

                        </div>


                      </div>
                    </Form.Group>
                  </Form>
                </div>

                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <Form.Group controlId="Last_Name">
                      <Form.Label className="required">Upload Image*</Form.Label>
                      <div>
                        <UploadButton uploader={uploader}
                          options={options}
                          onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                          {({ onClick }) =>
                            <button onClick={onClick}>
                              Upload a file...
                            </button>
                          }
                        </UploadButton>

                      </div>
                    </Form.Group>
                  </div>
                </div>



                <div className="col-md-6">
                  <div className="form-field">
                    <Form.Group controlId="Last_Name">
                      <Form.Label className="required">Upload KYC Doc (Adhar Card, Driving License, Passport)</Form.Label>
                      <div>
                        <UploadButton uploader={uploader}
                          options={options}
                          onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                          {({ onClick }) =>
                            <button onClick={onClick}>
                              Upload a file...
                            </button>
                          }
                        </UploadButton>

                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>

            </Form>
          </div>
        </div>

        <div className=" text-end apply_now_btn">


          <Button onClick={props.prevStep} className="back-btn me-3">
            Back
          </Button>

          {/* <div className="registerContinueMain"> */}
          <Button type="button" onClick={props.nextStep} className="tabs-btn">
            Next
          </Button>

          {/* </div> */}
        </div>

      </section>
    </>
  );
}

export default Stepsix;
