import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./Return_Reason_Modal.css";

function Return_Reason_Modal({ headingText, imgSrc, ...props }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className=" Cancel_Reason_Modal Return_Reason_Modal"
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // show={show} onHide={handleClose}
        >
          <Modal.Header closeButton>{headingText}</Modal.Header>
          <Modal.Body>
            <div className="preview-holder">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-6">
                  <input type="file" onChange={handleImageChange} />
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="img-preview-holder">
                    {selectedImage && (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-preview"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="select_reason">
              <Form>
                <Form.Check
                  label="Product price has reduced"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Wrong contact number entered"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Ordered by mistake"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Incorrect product size/colour/type ordered"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Purchased product from somewhere else"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Wrong address selected"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Product not required anymore"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Incorrect payment method selected"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Expected delivery time is too long"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
                <Form.Check
                  label="Other"
                  name="name"
                  type="radio"
                  onClick={props.onHide}
                />
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Return_Reason_Modal;
