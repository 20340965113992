import { Tab } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import Notification_Card from "../Common-Component/Notification_Card/Notification_Card";
import "./Notification.css"

const Notification = ({ handleClose, show }) => {
  const tabItems = [
    { eventKey: "all", title: "All" },
    { eventKey: "offers", title: "Offers" },
    { eventKey: "general", title: "General" },
    { eventKey: "delivery", title: "Delivery" },
  ];

  const notificationData = {
    all: [
      {
        infoText: "Order #2233 Is Ready to Dispatch",
        time: "1 minute ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/arc-img.png",
      },
      {
        infoText: "50% off on this items. Click here to check products",
        time: "5 minutes ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/noti-card-img-1.png",
      },
      {
        infoText: "Order #2233 Is Delivered",
        time: "20 minutes ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/noti-card-img-2.png",
      },
    ],
    offers: [
      {
        infoText: "Order #2233 Is Ready to Dispatch",
        time: "1 minute ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/arc-img.png",
      },
    ],
    general: [
      {
        infoText: "Order #2233 Is Ready to Dispatch",
        time: "1 minute ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/arc-img.png",
      },
    ],
    delivery: [
      {
        infoText: "Order #2233 Is Ready to Dispatch",
        time: "1 minute ago",
        imageUrl: process.env.PUBLIC_URL + "/assets/Image/notification/arc-img.png",
      },
    ],
  };

  return (
    <section className='Notification'>
      <Offcanvas
        className="notification-offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="notifications-banner-img-holder">
            <img
              className="notifications-banner-img"
              src={process.env.PUBLIC_URL + "/assets/Image/notification/notifications-banner.png"}
              alt="notifications-banner-img"
            />
          </div>

          <div className="notification-tab-holder">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">

              <div className="row">
                  <Nav variant="pills">
                      {tabItems.map((item, index) => (
                          <Nav.Item>
                            <Nav.Link eventKey={item.eventKey}>
                              {item.title}
                            </Nav.Link>
                          </Nav.Item>
                      ))}
                    </Nav>
                <div className="col-md-12">
                  <Tab.Content>
                    {tabItems.map((item) => (
                      <Tab.Pane eventKey={item.eventKey} key={item.eventKey}>
                        {notificationData[item.eventKey].map((notification, index) => (
                          <Notification_Card
                            key={index}
                            infoText={notification.infoText}
                            time={notification.time}
                            imageUrl={notification.imageUrl}
                          />
                        ))}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Notification;
