import React, { useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "./SelectByCategories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { Link } from "react-router-dom";

import {  Navigation } from 'swiper/modules';

library.add(fas);

const categories = [
  {
    name: "Thums Up 1 Lit.",
    subText: "Thums Up 1 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/1s.png",
  },
  {
    name: "Sprite ",
    subText: "Sprite 2 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/2s.png",
  },
  {
    name: "Nestea",
    subText: "Nestea Original Lemon Icetea",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/3s.png",
  },

  {
    name: "Thums up ",
    subText: "Thums Up 3 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/1s.png",
  },
  {
    name: "Sprite ",
    subText: "Sprite 2 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/2s.png",
  },
  {
    name: "Thums up ",
    subText: "Thums Up 1 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/1s.png",
  },
  {
    name: "Sprite 2 Lit.",
    subText: "Sprite 2 Lit.",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/2s.png",
  },
  {
    name: "Nestea",
    subText: "Nestea Original Lemon Icetea",
    price: "₹ 00.00/-",
    image: "/assets/Image/Homepage/Select-Categories/3s.png",
  },
];

const SelectByCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show
  const [showAll, setShowAll] = useState(false); // State to track whether to show all cards or not

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      // If currently showing all, reduce the number of rows displayed
      setNumToShow(4);
    } else {
      // If currently not showing all, show all rows
      setNumToShow(categories.length);
    }
  };

  const categoryData = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/beverage.png",
      content: "Beverages",
      eventKey: "first",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/cafee.png",
      content: "Coffee Prodcuts",
      eventKey: "second",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/daily-product.png",
      content: "Food Prodcuts",
      eventKey: "third",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/electronic.png",
      content: "Home Supplements, Utilities & Stationery",
      eventKey: "fourth",
    },

    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/vegetable.png",
      content: "Vegetables",
      eventKey: "fifth",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/How-to-choose.png",
      content: "Baby Needs",
      eventKey: "sixth",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/dairy-product.png",
      content: "Dairy Products",
      eventKey: "seven",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/beverage.png",
      content: "Beverages",
      eventKey: "eight",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/Select-Categories/cafee.png",
      content: "Coffee Prodcuts",
      eventKey: "nine",
    },
  ];


  return (
    <section className="select-categories">
      <Container>
        <div className="mb-4">
          <h2 className="title">Select by Category</h2>
        </div>
        {/* tab */}
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="me-0 ms-0 ">
            <Nav variant="pills" className="flex-row p-0">
              <Swiper
                className="mySwiper1 "
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                  },
                  420: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },

                  768: {
                    slidesPerView: 3.5,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 5.5,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 6.5,
                    spaceBetween: 10,
                  },
                }}
              >
                {categoryData.map((slide, index) => (

                  <SwiperSlide key={index} className="product-slide">
                    <Nav.Item>
                      <Nav.Link eventKey={slide.eventKey}>
                        <Popular_Product_Card
                          className="product-image"
                          content={slide.content}
                          image={slide.image}
                          imgClassName="category-image"
                        />
                        <div className="triangle-right"></div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>

                ))}
              </Swiper>
            </Nav>
          </Row>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="desktop_view_product_category_slider">
                <Row>
                  {/* desktop_view_product_category_slider start */}

                  {categories.slice(0, numToShow).map((category, index) => (
                    <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                      <Link to={"/vendor-page"}>
                        <Product_Card
                          image={category.image}
                          text={category.name}
                          subText={category.subText}
                          price={category.price}
                        />
                      </Link>
                    </Col>
                  ))}
                </Row>
              </div>

              {/* desktop_view_product_category_slider end */}

              {/* mobile_view_product_category_slider start */}

              <div className="mobile_view_product_category_slider">
                <Row className="ms-0 me-0">
                  <Swiper
                    className="select-categories-slider mySwiper"
                    modules={[Pagination]}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      420: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      486: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      992: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    {categories.slice(0, numToShow).map((category, index) => (
                      <SwiperSlide>
                        <Link to={"/vendor-page"}>
                          <Product_Card
                            image={category.image}
                            text={category.name}
                            subText={category.subText}
                            price={category.price}
                          />
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Row>
              </div>

              {/* mobile_view_product_category_slider end */}
            </Tab.Pane>
            <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        {(numToShow < categories.length || showAll) && (
          <div className="view-all mb-5" onClick={toggleShowAll}>
            <p>{showAll ? "View Less" : "View All"}</p>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SelectByCategories;
