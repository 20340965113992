import React, { useState } from "react";
import "./VegetableTab.css";
import { Link } from "react-router-dom";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Veggies_Section_Card from "../../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Category_Card from "../../../Common-Component/Category_Card/Category_Card";
import Veiw_All_Card from "../../../Common-Component/Veiw_All_Card/Veiw_All_Card";
const VegetableTab = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleHeartClick = (index) => {
    const updatedHearts = [...activeHearts];
    updatedHearts[index] = !updatedHearts[index];
    setActiveHearts(updatedHearts);
  };

  const VegetabelDetails = [
    {
      id: 1,
      shoesName: "Cabbage",
      color: "1 Pc (Approx. 600 -1000 gm)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/cabbage.png",
      detailsLink: "/vendor-page",
    },
    {
      shoesName: "Potato",
      color: "Per Kg",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/potato.png",
    },
    {
      shoesName: "Tomato",
      color: "Per Kg",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/tomato.png",
    },
    {
      shoesName: "Spainach",
      color: "1 Bunch (Appro. 100 - 300 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/spinach.png",
    },
    {
      shoesName: "Cabbage",
      color: "1 Pc (Approx. 600 -1000 gm)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/cabbage.png",
    },
    {
      shoesName: "Potato",
      color: "Per Kg",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/potato.png",
    },
    {
      shoesName: "Tomato",
      color: "Per Kg",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/tomato.png",
    },
    {
      shoesName: "Spainach",
      color: "1 Bunch (Appro. 100 - 300 gms)",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/spinach.png",
    },
  ];

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="vegfirst">
        <Row className="vegetable-sec">
          <Col
            lg={3}
            md={4}
            sm={12}
            className="px-lg-0 px-md-0 px-sm-none px-none "
          >
            <div className="bg-color">
              <div className="product-name py-3">
                <h2 className="ms-3">Vegetables</h2>
              </div>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="vegfirst">Daily Vegetables</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vegsecond">Exotic Vegetables</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vegthird">Herbs & Seasoning</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="vegfourth">Spouts</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
          <Col lg={9} md={8} className="px-lg-0 px-md-0 px-sm-none px-none">
            <Tab.Content>
              <Tab.Pane eventKey="vegfirst">
                <Row>
                  <Col
                    lg={3}
                    md={6}
                    sm={6}
                    className="px-lg-0 px-md-0 px-sm-none px-none"
                  >
                    <Category_Card
                      image="/assets/Image/product/pro-img.png"
                      discount="SALE 30% OFF"
                      heading="Fresh Vegetables"
                    />
                  </Col>
                  <Col
                    lg={9}
                    md={6}
                    sm={6}
                    className="px-lg-0 px-md-0 px-sm-none px-none"
                  >
                    <Swiper
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        420: {
                          slidesPerView: 1,
                        },
                        486: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        992: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 3,
                        },
                        1400: {
                          slidesPerView: 4,
                          spaceBetween: 0,
                        },
                      }}
                      navigation={false}
                      // modules={[Navigation]}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {VegetabelDetails.map((item, index) => (
                        <SwiperSlide key={`${index}-${item.detailsLink}`}>
                          {index < VegetabelDetails.length - 1 ? (
                            <Veggies_Section_Card
                              detailsLink={item.detailsLink}
                              image={item.image}
                              shoesName={item.shoesName}
                              color={item.color}
                              price={item.price}
                              offprice={item.offprice}
                            />
                          ) : (
                            <Link to="/view-all-product">
                              <Veiw_All_Card />
                            </Link>
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div class="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      ></div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      ></div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="vegsecond">Exotic Vegetables</Tab.Pane>
              <Tab.Pane eventKey="vegthird">FHerbs & Seasoning</Tab.Pane>
              <Tab.Pane eventKey="vegfourth">Spouts</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default VegetableTab;
