import React, { useEffect, useState } from "react";
import "./Wishlist.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { Card, CardFooter, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SupermarketModal from "../Common_modal/Supermarket-modal/SupermarketModal";
import Product_Card from "../Common-Component/Product_Card/Product_Card";
library.add(fas);
const Wishlist = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const [modalShow, setModalShow] = React.useState(false);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleHeartClick = (index) => {
    const updatedHearts = [...activeHearts];
    updatedHearts[index] = !updatedHearts[index];
    setActiveHearts(updatedHearts);
  };


  const hideModalWithDelay = () => {
    setTimeout(() => {
      // window.location.href = "/wishlist";
      setModalShow(false);
    }, 3000);
  };
  const wishlistProducts = [
    {
      name: "Nescafe Classic Instant Coffee...",
      subText: "Classic Instant Coffee Powder",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/1p.png",
    },
    {
      name: "Kurkure Toofaani Mirch Chips",
      subText: "Toofaani Mirch Chips",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/2p.png",
    },
    {
      name: "Beet Root 1 KG",
      subText: "",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/3p.png",
    },
    {
      name: "Himalaya Moisturizing Body Lotion",
      subText: "Moisturizing Body Lotion",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/4p.png",
    },
    {
      name: "Huggies Wonder Pants",
      subText: "Wonder Pants",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/5p.png",
    },
    {
      name: "Dettol Antiseptic Liquid",
      subText: "Antiseptic Liquid",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/6p.png",
    },
    {
      name: "Thums Up 1 Lit.",
      subText: "Thums Up 1 Lit.",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/7p.png",
    },
    {
      name: "Nescafe Classic Instant Coffee...",
      subText: "Classic Instant Coffee Powder",
      price: "₹ 00.00/-",
      image: "/assets/Image/Homepage/Our-Product/1p.png",
    },
  ];
  return (
    <>
      <section className="wishlist">
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Wishlist</Breadcrumb.Item>
            </Breadcrumb>
            {wishlistProducts.map((product, index) => (
              <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
              <Link to={"/vendor-page"}>
                <Product_Card
                  image={product.image}
                  text={product.name}
                  subText={product.subText}
                  price={product.price}
                />
                </Link>
              </Col>
            ))}
            <div className="view-all-product  mt-5">
              <p>Add all product into cart</p>
            </div>
          </Row>
        </Container>
      </section>
      <SupermarketModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Wishlist;
