import React from "react";
import "./HappyCustomer_card.css";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function HappyCustomer_card({ imgSrc, name, review, rating, cityName }) {
  return (
    <section className="HappyCustomer_card">
      <div className="client-div mt-3 mb-5">
        <Row>
          <Col lg={3} md={4} sm={4} xs={4}>
            <div className="main-client">
              <img className="client-img" src={imgSrc} alt="" />
            </div>
          </Col>
          <Col lg={9} md={8} sm={8} xs={8}>
            <h5 className="client-name">{name}</h5>
          </Col>
        </Row>
        <div className="mt-3">
          <p className="client-review">{review}</p>
          <p className="city-text-holder"> <FontAwesomeIcon className="me-2" icon={faLocationDot}/>{cityName}</p>
          <div className="d-flex">
            {[...Array(rating)].map((_, i) => (
              <div key={i}>
                <FontAwesomeIcon
                  icon="fa-solid fa-star"
                  className="star-icon mt-3"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HappyCustomer_card;
