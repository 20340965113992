import React, { useState } from "react";
import "./HappyCustomer.css";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import HappyCustomerCard from "../../Common-Component/HappyCustomer_card/HappyCustomer_card";

library.add(fas);

const HappyCustomer = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const clientReviews = [
    {
      name: "Kristina",
      review:
        "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
    {
      name: "Rose Merry",
      review:
        "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
    {
      name: "John Doe",
      review:
        "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
    {
      name: "Kristina",
      review:
        "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
    {
      name: "Rose Merry",
      review:
        "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
    {
      name: "John Doe",
      review:
        "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
      rating: 5,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
      cityName: "Pune",
    },
  ];

  return (
    <section className="happy-clients">
      <Container>
        <Row>
          <Col className="text-center mb-4">
            <h3 className="hot-title">Happy Customers</h3>
            <div className="title-border"></div>
          </Col>

          <Swiper
            spaceBetween={30}
            pagination={{ dynamicBullets: true }}
            modules={[Pagination]}
            autoplay={{ delay: 3000 }} // Added more delay for better readability
            onSwiper={setSwiperInstance}
            className="mySwiper happy-clients-swiper"
            breakpoints={{
              0: { slidesPerView: 1 },
              420: { slidesPerView: 1, spaceBetween: 20 },
              486: { slidesPerView: 2, spaceBetween: 20 },
              768: { slidesPerView: 2 },
              992: { slidesPerView: 3 },
              1200: { slidesPerView: 3 },
            }}
          >
            {clientReviews.map((review, index) => (
              <SwiperSlide key={index}>
                <HappyCustomerCard
                  imgSrc={review.imgSrc}
                  name={review.name}
                  review={review.review}
                  cityName={review.cityName}
                  rating={review.rating}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="slider-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            >
              <FontAwesomeIcon icon="fas fa-chevron-left" />
            </div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            >
              <FontAwesomeIcon icon="fas fa-chevron-right" />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default HappyCustomer;
