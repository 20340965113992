import React from "react";
import Common_Button from "../Common_Button/Common_Button";
import "./Membership__Modal_card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faStar } from "@fortawesome/free-solid-svg-icons";
import Membership_card_modal from "../../Common_modal/Membership_card_modal/Membership_card_modal";

function Membership__Modal_card({ btnText, headingText, amountText }) {
  const [modalShow, setModalShow] = React.useState(false);

  const benefits = [
    { icon: faCheck, text: "Early access to deals.", isCheck: true },
    { icon: faCheck, text: "Unlock exclusive member deals.", isCheck: true },
    { icon: faXmark, text: "Celebrate your birthday perks!", isCheck: false },
    { icon: faXmark, text: "Get VIP customer support.", isCheck: false },
    {
      icon: faXmark,
      text: "Unlock extra discounts selectively",
      isCheck: false,
    },
    { icon: faXmark, text: "Early access to deals.", isCheck: false },
    { icon: faXmark, text: "Unlock top discounts everywhere.", isCheck: false },
    { icon: faXmark, text: "Always enjoy free shipping.", isCheck: false },
    { icon: faXmark, text: "Come to exclusive VIP events.", isCheck: false },
    { icon: faXmark, text: "Flexible return and exchange.", isCheck: false },
    { icon: faStar, text: "Rs.3000  Minimum amount spend", isMainText: true },
  ];

  const Services = [
    { icon: faCheck, text: "Free RO Water Bottle X 2", isCheck: true },
    {
      icon: faCheck,
      text: "Free Inverter Battery Checkup & Distle Water Top Up",
      isCheck: true,
    },
    { icon: faXmark, text: "Free Delivery", isCheck: false },
    {
      icon: faXmark,
      text: "Free 3-5 Page Xerox With Free Home Delivery",
      isCheck: false,
    },
    {
      icon: faXmark,
      text: "Security Siren Device Installation @100/- Per Month Rental Optional",
      isCheck: false,
    },
  ];

  return (
    <>
      <section className="Membership_card Membership__Modal_card">
        <div className="card">
          <div className="card-holder">
            <div className="card-header">
              <div className="heading-holder">
                <h3>{headingText}</h3>
              </div>
            </div>
            <div className="card-body">
              <div className="list-holder">
                <ul>
                  {benefits.map((benefit, index) => (
                    <li
                      key={index}
                      className={benefit.isMainText ? "main-text" : ""}
                    >
                      <FontAwesomeIcon
                        className={
                          benefit.isCheck
                            ? "check-icon"
                            : benefit.isMainText
                            ? "star-icon"
                            : "cross-icon"
                        }
                        icon={benefit.icon}
                      />{" "}
                      {benefit.text}
                    </li>
                  ))}
                  <hr />
                  <div className="heading-holder">
                    <h3>Free Services to Membership Holders</h3>
                  </div>
                  {Services.map((service, index) => (
                    <li
                      key={index}
                      className={service.isMainText ? "main-text" : ""}
                    >
                      <FontAwesomeIcon
                        className={
                          service.isCheck
                            ? "check-icon"
                            : service.isMainText
                            ? "star-icon"
                            : "cross-icon"
                        }
                        icon={service.icon}
                      />{" "}
                      {service.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Membership_card_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Membership__Modal_card;
