import React, { useEffect } from 'react'
import FirstVendor from './FirstVendor/FirstVendor'
import SecondVendor from './SecondVendor/SecondVendor'
import RateAndReview from './RateAndReview/RateAndReview'

const VendorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <FirstVendor />
      <RateAndReview />
      <SecondVendor  heading="You may also like"/>
    </>
  )
}

export default VendorPage