import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
const PurchasedItemReturnModal = (props) => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal  Purchased_Items_return_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div ">
              <div className="row">
                <div className="col-xxl-5 col-lg-5 col-md-7 col-sm-6 col-12">
                  <div className="border-text-right">
                    <p className="date">Ordered Date : 20 Dec 2022</p>
                    <h6 className="items">Purchased Items 2</h6>
                    <h6 className="price">₹100</h6>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-7 col-md-5 col-sm-6 col-12">
                  <div>
                    <p className="date">Order ID : #867564756</p>
                    <p className="date">Payment Type : Online</p>
                    <p className="date">Payment Status : Paid</p>
                  </div>
                </div>
              </div>

              <div className="product-div">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3  col-3   ">
                    <div className="product">
                      <img
                        className="img-set"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/My_Profile/cabbege.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="stepper-div mb-md-5 mb-sm-5 ">
                <div className="pb-md-0 pb-sm-3 pb-4">
                  <h6 className="order ">Order Return Tracking</h6>
                </div>
                <ProgressBar percent={stepPercentage}>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Requested</h6>
                          <p className="date">
                            Seller has processesd your
                            <br /> order. Mon 1st Apr
                          </p>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Approved</h6>
                          <p className="date">Expected date of pick up 6 Apr</p>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Product Picked</h6>
                          <p className="date">Date of Pick up 5 Apr</p>
                        </div>
                      </div>
                    )}
                  </Step>

                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Refund Process</h6>
                          <p className="date">5 - 6 Working days</p>
                        </div>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PurchasedItemReturnModal;
