import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "./Veggies_Section_Card.css";
import Common_Button from "../Common_Button/Common_Button";
import { Link } from "react-router-dom";
import Heart_Component from "../Heart_Component/Heart_Component";

function Veggies_Section_Card({
  image,
  shoesName,
  color,
  price,
  offprice,
  detailsLink,
}) {
  const [likedProducts, setLikedProducts] = useState(false); // State to track liked products

  const handleLike = () => {
    setLikedProducts(!likedProducts);
  };

  return (
    <div className="veggies-section mb-5">
      <Card className="veggies ">
        {/* <div className="background like-heart">
          <Button
            variant="light"
            className="button-holder"
            type="button"
            onClick={handleLike}
          >
            <FontAwesomeIcon
              icon={faHeart}
              className={likedProducts ? "heart-icon active" : "heart-icon"}
            />
          </Button>
        </div> */}

        <Heart_Component />
        <div className="image-main">
          <Link to={detailsLink}>
            <Card.Img src={image} className="electronic-img" />
          </Link>
        </div>

        <Card.Body>
          <p className="card-heading">{shoesName}</p>
          <p className="color">{color}</p>
          <div className="cart-price-holder">
            <div className="price">
              <p className="color1 me-4">{price}</p>
              <p className="color3">{offprice}</p>
            </div>
            <div className="cart">
              <div className="background cart-btn">
                <Link to="/vendor-page">
                  <Button
                    variant="light"
                    className="button-holder"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Veggies_Section_Card;
