import React, { useEffect } from "react";
import "./Membership.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Navigation } from 'swiper/modules';
import Membership_card from "../Common-Component/Membership_card/Membership_card";

const membershipData = [
  { headingText: "Silver Membership", btnText: "View More" , amountText:"30000"  },
  { headingText: "Gold Membership", btnText: "View More" , amountText:"60000" },
  { headingText: "Platinum Membership", btnText: "View More" , amountText:"100000"},
  { headingText: "Silver Membership", btnText: "View More" , amountText:"30000"  },
  { headingText: "Gold Membership", btnText: "View More" , amountText:"60000" },
  { headingText: "Platinum Membership", btnText: "View More" , amountText:"100000"},
];

const Membership = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <section className="Membership TermsCondition">
        <div className="container">
          <div className="relation-div">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="Membership-banner-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Image/membership/membership-banner.png"
                    }
                    className="Membership-banner"
                    alt="Membership Banner"
                  />
                </div>
              </div>
            </div>
            <div className="absolute-content">
              <div className="heading-holder">
                <h1>Membership</h1>
              </div>
              <div className="row me-0 ms-0 justify-content-center">
                <div className="col-xl-10 col-md-11 col-sm-11">
                  <div className="Membership-card-holder">
                    <div className="row justify-content-center">
                      <Swiper
                       navigation={true} modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={3}

                        className="Membership_card_swiper"

                        breakpoints={{
                          280: {
                            slidesPerView: 1,
                          },

                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 2,
                          },
                          1200: {
                            slidesPerView: 3,
                          },
                          1400: {
                            slidesPerView: 3,
                          },
                        }}
                      >


                        {membershipData.map((membership, index) => (
                          <SwiperSlide key={index}>
                            <div className="card-holder">
                              <Membership_card
                                headingText={membership.headingText}
                                btnText={membership.btnText}
                                amountText={membership.amountText}
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                          


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Membership;
