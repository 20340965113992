import React from "react";
import "./AboutBanner.css";
const AboutBanner = () => {
    return (
        <>
            <section className="about-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto mt-4">
                            <div className="banner">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/Image/about/about-banner.png"}
                                    className="about-banner"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutBanner;
