import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../wallet.css";
import cardimg from "../image/debit-card.png";
import payatmimg from "../image/Paytm.png";
import upiimg from "../image/UPI-Logo.png";
import debitimg from "../image/creditcard.png";
import phonepayimg from "../image/phonepay.png";
import CheckLottey from "../check-lottey/CheckLottey";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
const WalletPayment = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="wallet desk-top-view-wallet">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/wallet">Wallet</Link>
                </li>
                <li className="breadcrumb-item active">Payment Information</li>
              </ol>
            </nav>
          </div>
          <div className="row wallet-payment mt-5">
            <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
              <div className="card">
                <div className="card-body">
                  <div className="payment-div">
                    <h5 className="mb-3">Payment Details</h5>
                    <div className="row">
                      <div className="col-6">
                        <div className="amount">
                          <h6>Total amount</h6>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="rupees text-end">
                          <p>₹0.00</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="amount">
                          <h6>GST 18%</h6>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="rupees text-end">
                          <p>₹0.00</p>
                        </div>
                      </div>
                      <hr />
                      <div className="col-6">
                        <div className="amount">
                          <h6>Total payable amount</h6>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="rupees text-end">
                          <p>₹0.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6">
                      <div className=" payment-mode  mb-3">
                        <div className="imag-card">
                          <img src={cardimg} className="card-img me-2" />
                          <span className="card-name">Credit Card</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6">
                      <div className=" payment-mode  mb-3">
                        <div className="imag-card">
                          <img src={payatmimg} className="pay-img me-1" />
                          <span className="card-name">Paytm</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6">
                      <div className=" payment-mode  mb-3">
                        <div className="imag-card">
                          <img src={upiimg} className="upi-img me-2" />
                          <span className="card-name">UPI</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6">
                      <div className=" payment-mode  mb-3">
                        <div className="imag-card">
                          <img src={debitimg} className="debit-img me-2" />
                          <span className="card-name">Debit Card</span>
                        </div>
                      </div>
                    </div>
                    <div className=" col-xxl-4 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6">
                      <div className=" payment-mode  mb-3">
                        <div className="imag-card">
                          <img
                            src={phonepayimg}
                            className="phonepay-img me-2"
                          />
                          <span className="card-name">Phone Pay</span>
                        </div>
                      </div>
                    </div>
                    <div className="button -holder text-center mt-3">
                      <button
                        type="button"
                        className="btn btn-proceed"
                        onClick={() => setModalShow(true)}
                      >
                        Proceed
                      </button>
                    </div>
                    <Successfull_Modal
                      message={"Payment Proceed Successfully "}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      {/* <section className='wallet mobile-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to='/wallet'>Wallet {'>'}</Link></li>
                                <li className="breadcrumb-item active" >Payment Information</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row wallet-payment mt-5'>
                        <div className=' col-md-9 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="payment-div">

                                        <div className="row">
                                            <div className='col-12'>
                                                <h5 className='mb-1'>Payment Details</h5>
                                                <div className='border-payment mb-2'></div>
                                            </div>
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹0.00</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>GST 18%</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹0.00</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total payable amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹0.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'>
                                                <div className='imag-card'>
                                                    <img src={cardimg} className='card-img me-1' /><span className='card-name'>Credit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'>
                                                <div className='imag-card'>
                                                    <img src={payatmimg} className='pay-img me-1' /><span className='card-name'>Paytm</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode1  me-sm-5 me-0 mb-3'>
                                                <div className='imag-card'>
                                                    <img src={upiimg} className='upi-img me-1' /><span className='card-name'>UPI</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'>
                                                <div className='imag-card'>
                                                    <img src={debitimg} className='debit-img me-1' /><span className='card-name'>Debit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode mb-3'>
                                                <div className='imag-card'>
                                                    <img src={phonepayimg} className='phonepay-img me-2' /><span className='card-name'>Phone Pay</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='button -holder text-center mt-3'>
                                            <button type='button' className='btn btn-proceed' data-bs-toggle="modal" data-bs-target="#exampleModal">Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* mobile-view-end */}
    </>
  );
};

export default WalletPayment;
