import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";

import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ThreeOrder = ({ handlePrevious }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [numberTwo, setNumberTwo] = useState(1);
  const [numbOne, setNumberOne] = useState(1);

  const handleMiClickOne = () => {
    if (numbOne > 1) {
      setNumberOne(numbOne - 1);
    }
  };

  const handlePlClickOne = () => {
    setNumberOne(numbOne + 1);
  };

  const handleMiClickTwo = () => {
    if (numberTwo > 1) {
      setNumberTwo(numberTwo - 1);
    }
  };

  const handlePlClickTwo = () => {
    setNumberTwo(numberTwo + 1);
  };

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="confirm_order Product_Cart threeorder">
            <div className="row">
              <div className="col-lg-9">
                <div className="product_table">
                  {/* <Table responsive bordered>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Image</th>
                        <th>Item Name</th>
                        <th>Tax</th>
                        <th>MRP</th>
                        <th>Order Price</th>
                        <th>Tax</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div className="Product_Cart_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Image/Product_Cart/Cabbage.png"
                              }
                              className="cart_img"
                            />
                          </div>
                        </td>
                        <td>Cabbage</td>

                        <td>12%</td>
                        <td>₹30</td>
                        <td>₹30</td>
                        <td>₹5</td>
                        <td>₹35</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <div className="Product_Cart_img">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Image/Product_Cart/Tomato.png"
                              }
                              className="cart_img"
                            />
                          </div>
                        </td>
                        <td>Tomato</td>

                        <td>12%</td>
                        <td>₹50</td>
                        <td>₹50</td>
                        <td>₹5</td>
                        <td>₹55</td>
                      </tr>
                    </tbody>
                  </Table> */}

                  <div className="row tabless mb-4">
                    <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-2">
                      <div className="cabbageimg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/Product_Cart/Cabbage.png"
                          }
                          className="cartimg"
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-4">
                      <div className="prices">
                        <p className="cabbage">Cabbage</p>
                        <p className="prce me-2">
                          Price : <span>₹30</span>
                        </p>
                        <p className="prce me-2">
                          Tax : <span>12%</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-5">
                      <div className="prices">
                        <p className="cabbage cabbage2">Cabbage</p>
                        <p className="prce">
                          Order Price: <span>₹30</span>
                        </p>
                        <p className="prce">
                          Tax : <span>₹5</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                      <div className="prices">
                        <p className="cabbage cabbage2">Cabbage</p>
                        <p className="prce">
                          Total Price: <span>₹35</span>
                        </p>
                        <div className="qnty">
                          <p className="prce mb-0">Quantity:</p>
                          <div className="counting">
                            <button
                              className="count-btn"
                              type="button"
                              onClick={handleMiClickTwo}
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-minus"
                                className="minnusicns"
                              />
                            </button>
                            <p className=" prce nombers">{numberTwo}</p>
                            <button
                              className="count-btn"
                              type="button"
                              onClick={handlePlClickTwo}
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-plus"
                                className="plusicns"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row tabless mb-3">
                    <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-2">
                      <div className="cabbageimg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/Product_Cart/Tomato.png"
                          }
                          className="cartimg"
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-4">
                      <div className="prices">
                        <p className="cabbage">Cabbage</p>
                        <p className="prce me-2">
                          Price : <span>₹30</span>
                        </p>
                        <p className="prce me-2">
                          Tax : <span>12%</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-5">
                      <div className="prices">
                        <p className="cabbage cabbage2">Tomato</p>
                        <p className="prce">
                          Order Price: <span>₹30</span>
                        </p>
                        <p className="prce">
                          Tax : <span>₹5</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                      <div className="prices">
                        <p className="cabbage cabbage2">Cabbage</p>
                        <p className="prce">
                          Total Price: <span>₹35</span>
                        </p>
                        <div className="qnty">
                          <p className="prce mb-0">Quantity:</p>
                          <div className="counting">
                            <button
                              className="count-btn"
                              type="button"
                              onClick={handleMiClickOne}
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-minus"
                                className="minnusicns"
                              />
                            </button>
                            <p className=" prce nombers">{numbOne}</p>
                            <button
                              className="count-btn"
                              type="button"
                              onClick={handlePlClickOne}
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-plus"
                                className="plusicns"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 ">
                <div className="grand_total_table">
                  <div className="orderss">
                    <h6 className="ordersum">Order Summary</h6>
                  </div>

                  <Table responsive bordered>
                    <thead>
                      <tr className="top-border">
                        <th>Total amount</th>
                        <td>₹90</td>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>Discount</th>
                        <td>₹0</td>
                      </tr>
                    </thead>

                    <thead>
                      <tr>
                        <th>Tax</th>
                        <td>₹0</td>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>Shipping</th>
                        <td>₹0</td>
                      </tr>
                    </thead>

                    <thead>
                      <tr className="bottom-border">
                        <th className="bold-text">Grand Total</th>
                        <td className="bold-text">₹90</td>
                      </tr>
                    </thead>
                  </Table>
                </div>

              </div>

             
            </div>
          </div>
          <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            <Common_Button
              onClick={() => {
                setModalShow(true);
                hideModalWithDelay();
              }}
              text="Confirm Order"
              className=" save-btn"
            />
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your order has been placed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ThreeOrder;
