import { React, useState } from "react";
import "./SecondVendor.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";

const SecondVendor = ({heading}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleHeartClick = (index) => {
    const updatedHearts = [...activeHearts];
    updatedHearts[index] = !updatedHearts[index];
    setActiveHearts(updatedHearts);
  };

  const VegetabelsDetails = [
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img3.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img2.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img4.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img5.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img2.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img3.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img4.png",
    },
    {
      shoesName: "Shoes for men",
      color: "White - Orange",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/Vendor-Page/shoes-img5.png",
    },
  ];

  return (
    <>
      <section className="Frequently-Bought">
        <Container>
          <h1 className="title">{heading}</h1>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 0,
              },
              1400: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
              1900: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
            className="mySwiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {VegetabelsDetails.map((item, index) => (
              <SwiperSlide key={index}>
                <Veggies_Section_Card
                  image={item.image}
                  shoesName={item.shoesName}
                  color={item.color}
                  price={item.price}
                  offprice={item.offprice}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div class="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SecondVendor;
