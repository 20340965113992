import React, { useRef, useEffect } from 'react';
import './newlylaunch.css';
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Mini_newly_launch from './Mini_newly_launch';
import Button from 'react-bootstrap/Button';

const Newly_launch = (props) => {
    const videoRefs = useRef([]);

    const launchproducts = [
        {
            video: '/assets/video/launch-product/first.mp4',
        },
        {
            video: '/assets/video/launch-product/first.mp4',
        },
        {
            video: '/assets/video/launch-product/first.mp4',
        },
    ];

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                video.addEventListener('play', handleVideoPlay);
            }
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) {
                    video.removeEventListener('play', handleVideoPlay);
                }
            });
        };
    }, []);

    const handleVideoPlay = () => {
        props.onHide();
    };

    const [modalShow, setModalShow] = React.useState(false);

    const handleMiniPlayerClick = () => {
        setModalShow(true);
        props.onHide();
    };

    return (
        <>
            <div className="">
                <Modal
                    className="Newly_launch_modal"
                    {...props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton >
                        {/* <Button variant="primary" onClick={handleMiniPlayerClick}>
                            Mini player 
                        </Button> */}
                    </Modal.Header>

                    <Modal.Body>
                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="Newly_launch_swiper"
                        >
                            {launchproducts.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className="content-holder">
                                        <div className="video-main">
                                            <video
                                                ref={(el) => (videoRefs.current[index] = el)}
                                                className="video-bg"
                                                loop
                                                controls
                                                muted
                                            >
                                                <source src={data.video} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Modal.Body>
                </Modal>

                <Mini_newly_launch
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </>
    );
}

export default Newly_launch;