import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../ScrollTotop/ScrollTotop";

// Import Swiper styles
import "swiper/css";
import Notification from "../Notification/Notification";
import { InputGroup } from "react-bootstrap";
library.add(fas);

const Header = () => {
  const location = useLocation();
  const [showNotification, setShowNotification] = useState(false);
  const handleCloseNotification = () => setShowNotification(false);
  const handleShowNotification = () => setShowNotification(true);

  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const categories = [
    { path: "/all-categories", name: "Coffee Products" },
    { path: "/all-categories", name: "Food Products" },
    {
      path: "/all-categories",
      name: "Home Supplements, Utilities & Stationery",
    },
    { path: "/all-categories", name: "Beverages" },
    { path: "/all-categories", name: "Vegetables" },
    { path: "/all-categories", name: "Baby Needs" },
  ];

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };

  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [searchToggled, setIsToggled] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleClick = () => {
    setIsToggled((prevState) => !prevState);
  };

  const tabItems = [
    { eventKey: "all", title: "All" },
    { eventKey: "offers", title: "Offers" },
    { eventKey: "general", title: "General" },
    { eventKey: "delivery", title: "Delivery" },
  ];


  function handleClick(el) {
    el.classList.toggle('slide-in-elliptic-top-fwd');
  }

  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setShow(false);
    // }, 4000); 

    // return () => clearTimeout(timer); 
  }, []);

  return (
    <>
      {/* start header section  */}

      <ScrollToTop />
      <section className="second-header-sec">
        <div className="container-fluid ">
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={process.env.PUBLIC_URL + "/assets/Image/Logo/logo.png"}
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="">
                <Nav.Link
                  as={Link}
                  to="/"
                  active={activeItem === "home"}
                  onClick={() => handleNavItemClick("home")}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/near-me"
                  active={activeItem === "near-me"}
                  onClick={() => handleNavItemClick("near-me")}
                >
                  Near Me
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/about-us"
                  active={activeItem === "about-us"}
                  onClick={() => handleNavItemClick("about-us")}
                >
                  About Us
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  active={activeItem === "contact-us"}
                  onClick={() => handleNavItemClick("contact-us")}
                >
                  Contact Us
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/blog"
                  active={activeItem === "blog"}
                  onClick={() => handleNavItemClick("blog")}
                >
                  Blog
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/become-seller"
                  active={activeItem === "become-seller"}
                  onClick={() => handleNavItemClick("become-seller")}
                >
                  Become a Seller
                </Nav.Link>

                <NavDropdown
                  title="All Categories"
                  id="basic-nav-dropdown"
                  className="me-3"
                >
                  {categories.map((category, index) => (
                    <Link key={index} to={category.path}>
                      <NavDropdown.Item
                        href={category.path}
                        onClick={() => handleNavItemClick(category.name)}
                      >
                        {category.name}
                      </NavDropdown.Item>
                    </Link>
                  ))}
                </NavDropdown>

                {/* <Nav.Link>
                  
                </Nav.Link> */}
              </Nav>

              <div className="all-icon mt-lg-0 mt-md-3 mt-sm-3 mt-3 row">
                <div className="col-lg-7  ">
                  {/* <div className="searchinpuuhead">
                    <Form.Control type="search" placeholder="Search here..." />
                    <FontAwesomeIcon
                      icon="fa-solid fa-magnifying-glass"
                      className="btnserch"
                    />
                  </div> */}
                  <InputGroup className="searchbar-holder">
                    <Form.Control type="search" placeholder="Search here..." />
                    <InputGroup.Text id="basic-addon2" 
                    // onClick={() => setShow(!show)}
                    >
                      {" "}
                      <div >
                        <FontAwesomeIcon
                          icon="fa-solid fa-magnifying-glass"
                          className="btnserch"

                        // onClick={({ target }) => handleClick(target)}
                        />
                      </div>


                    </InputGroup.Text>
                  </InputGroup>



                  {/* {show &&
                    <div>
                      <img className="ginanimationimg slide-in-elliptic-top-fwd" src={process.env.PUBLIC_URL + '/assets/Image/Animation/gin.png'} />


                      <img className="chiragdeskk " src={process.env.PUBLIC_URL + '/assets/Image/Animation/chirag.png'} />
                    </div>

                  } */}









                </div>

                <div className="col-lg-5  d-flex header-icon-holder">
                  <Link to={"/wallet"}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-wallet"
                      className="cart-logo"
                    />
                  </Link>

                  <FontAwesomeIcon
                    onClick={handleShowNotification}
                    icon="fa-solid fa-bell"
                    className="cart-logo"
                  />

                  <Link to={"/shop-now"}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-cart-shopping"
                      className="cart-logo"
                      onClick={() => handleNavItemClick("product-cart")}
                    />
                  </Link>

                  <Link to={"/empty-wislist"}>
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="cart-logo"
                      onClick={() => handleNavItemClick("empty-wislist")}
                    />
                  </Link>

                  <Link to={"/my-account"}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-user"
                      className="cart-logo"
                      onClick={() => handleNavItemClick("shop-now")}
                    />
                  </Link>

                  <Link to={"/login"} className="login">
                    Login
                  </Link>
                </div>

                {/* <FontAwesomeIcon
                  icon="fa-solid fa-magnifying-glass"
                  className="cart-logo"
                  onClick={toggleClick}
                /> */}

                {searchToggled && (
                  <div ref={searchRef} className="input-group-box mb-3">
                    <Form.Control type="text" placeholder="Search here" />
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>

          <div className="mobsearchhed">
            <div className="searchinpuuhead-mob">
              <Form.Control type="search" placeholder="Search here..." />
              <FontAwesomeIcon
                icon="fa-solid fa-magnifying-glass"
                className="btnserch"
                // onClick={() => setShow2(!show2)}
              />
            </div>
          </div>

          {/* {show2 &&
            <div>
              <img className="ginanimationimg slide-in-elliptic-top-fwd" src={process.env.PUBLIC_URL + '/assets/Image/Animation/gin.png'} />
            </div>
          } */}


        </div>
      </section>

      <Notification
        show={showNotification}
        handleClose={handleCloseNotification}
      />
      {/* end header section  */}
    </>
  );
};

export default Header;
