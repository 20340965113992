import { useState, useEffect } from "react";
import "./SellerstepForm.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import "react-circular-progressbar/dist/styles.css";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import Stepeight from "./Stepeight/Stepeight";
import Stepseven from "./Stepseven/Stepseven";
import Stepsix from "./Stepsix/Stepsix";
import Stepnine from "./Stepnine/Stepnine";

function SellerstepForm() {
  const [page, setPage] = useState(0);
  const [subPage, setSubPage] = useState(0); // State for sub-steps within Stepseven

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, subPage]);

  const nextStep = () => {
    if (page === 6 && subPage === 0) {
      setSubPage(1); // Move to the second form within Stepseven
    } else {
      setPage((currPage) => currPage + 1);
      setSubPage(0); // Reset subPage for the next step
    }
  };

  const prevStep = () => {
    if (page === 6 && subPage === 1) {
      setSubPage(0); // Move back to the first form within Stepseven
    } else {
      setPage((currPage) => currPage - 1);
      setSubPage(0); // Reset subPage for the previous step
    }
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Stepone nextStep={nextStep} heading={stepNames[0]} />;
      case 1:
        return <Stepeight nextStep={nextStep} prevStep={prevStep} />;
      case 2:
        return <Steptwo nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <Stepfive nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <Stepfour nextStep={nextStep} prevStep={prevStep} />;
      case 5:
        return <Stepsix nextStep={nextStep} prevStep={prevStep} />;
      case 6:
        return <Stepthree nextStep={nextStep} prevStep={prevStep} subPage={subPage} />;
      case 7:
        return <Stepnine nextStep={nextStep} prevStep={prevStep} />;
        case 8:
          return <Stepseven nextStep={nextStep} prevStep={prevStep} />;
      default:
        return null;
    }
  };

  const stepNames = [
    "Business Details",
    "product and trade ",
    "Certification & License",
    "GST/PAN Details",
    "Owner Details",
    "Bank Details",
    "Commission & Onboarding",
    "Onboarding Form",
    "E Signature",
  ];

  return (
    <>
      <section className="SellerStepMain">
        <div className="ProgressBar">
          <ProgressBar
            percent={(page / (stepNames.length - 1)) * 100}
            filledBackground="#0F3768"
            height="3px"
            style={{ margin: "auto" }}
          >
            {stepNames.map((name, index) => (
              <Step key={index}>
                {({ accomplished }) => (
                  <div
                    style={{
                      backgroundColor: index <= page ? "#0F3768" : "#ffffff",
                      color: index <= page ? "#fff" : "#000000",
                      border: index <= page ? "1px solid #0F3768" : "1px solid #FFFFFF",
                      boxShadow: index <= page ? "none" : "0px 0px 6px #00000029",
                    }}
                    className={`step ${index <= page ? "completed" : ""}`}
                  >
                    <p className="mb-0">{index + 1}</p>
                    <div className="step-name">{name}</div>
                  </div>
                )}
              </Step>
            ))}
          </ProgressBar>
        </div>

        <div className="userForm">
          <div className="step-Form-Container">
            {PageDisplay()}
          </div>
        </div>
      </section>
    </>
  );
}

export default SellerstepForm;
