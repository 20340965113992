import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";

function Stepfour(props) {
  const [selectedRole, setSelectedRole] = useState('');

  const handleRadioChange = (e) => {
    setSelectedRole(e.target.id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm();

  return (
    <section className="personal-details-form userForm">
      <div className="details-form-holder">
        <div className="form-container">
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Name of Owner*</Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerName"
                    placeholder="Enter Owner Name"
                    {...register("ownerName", {
                      required: "Owner name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.ownerName,
                      "is-valid": getValues("ownerName"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Owner Email ID*</Form.Label>
                  <Form.Control
                    type="email"
                    name="ownerEmail"
                    placeholder="Enter Owner Email"
                    {...register("ownerEmail", {
                      required: "Owner email required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.ownerEmail,
                      "is-valid": getValues("ownerEmail"),
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Owner Contact Number*</Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerContact"
                    placeholder="Enter Owner Contact Number"
                    {...register("ownerContact", {
                      required: "Owner contact number required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.ownerContact,
                      "is-valid": getValues("ownerContact"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.phoneNumber.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Invoicing Email ID*</Form.Label>
                  <Form.Control
                    type="email"
                    name="invoiceEmail"
                    placeholder="Enter Invoicing Email"
                    {...register("invoiceEmail", {
                      required: "Invoicing email required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.invoiceEmail,
                      "is-valid": getValues("invoiceEmail"),
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Role*</Form.Label>
                  <div className="row get-otp-text">
                    <div className="col-lg-6">
                      <Form.Check
                        type="radio"
                        label="Owner"
                        id="owner"
                        checked={selectedRole === "owner"}
                        name="role"
                        onChange={handleRadioChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Form.Check
                        type="radio"
                        label="Manager"
                        id="manager"
                        name="role"
                        checked={selectedRole === "manager"}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selectedRole === "owner" && (
              <div className="row">

              </div>
            )}

            {selectedRole === "manager" && (
              <div className="row">
                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">Manager Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="managerName"
                      placeholder="Enter Manager Name"
                      {...register("managerName")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">Manager Email ID</Form.Label>
                    <Form.Control
                      type="email"
                      name="managerEmail"
                      placeholder="Enter Manager Email"
                      {...register("managerEmail")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">Manager Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="managerContact"
                      placeholder="Enter Manager Contact Number"
                      {...register("managerContact")}
                      onKeyDown={(event) => {
                        if (!RegxExpression.phoneNumber.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div style={{ width: '100%' }} className="mb-4">
              <iframe
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=profcyma%20global%20solution+(Wesbite)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps trackers</a>
              </iframe>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Longitude</Form.Label>
                  <Form.Control
                    type="text"
                    name="longitude"
                    placeholder="Enter Longitude"
                    {...register("longitude")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Latitude</Form.Label>
                  <Form.Control
                    type="text"
                    name="latitude"
                    placeholder="Enter Latitude"
                    {...register("latitude")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Address</Form.Label>
                  <p className="addresstxt">E/92 APMC Market yard, Kothrud, Vanaz, Maharashtra-411038</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    placeholder="Enter Postal Code"
                    {...register("postalCode")}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                <div className="registerContinueMain">
                  <Button onClick={props.prevStep} className="back-btn me-3">
                    Back
                  </Button>
                  <Button className="tabs-btn" type="button" onClick={props.nextStep}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default Stepfour;
