import React, { useState, useEffect } from "react";
import "./Stepnine.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import CongratsSuccessmodal from "../../../Common_modal/CongratsSuccessmodal/CongratsSuccessmodal";

const Stepnine = (props) => {
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(
    "https://www.clickdimensions.com/links/TestPDFfile.pdf"
  ); // Replace with the actual path to the admin-uploaded PDF
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState(null);
  const [adminPdfName, setAdminPdfName] = useState("");

  

 

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is PDF
      if (file.type === "application/pdf") {
        const fileUrl = URL.createObjectURL(file);
        setUploadedPdfUrl(fileUrl);
      } else {
        // Notify the user that only PDF files are allowed
        alert("Please upload a PDF file.");
      }
    }
  };

  useEffect(() => {
    const fileName = pdfUrl.split("/").pop();
    setAdminPdfName(fileName);
  }, [pdfUrl]);

  return (
    <>
      <section className="personal-details-form">
        <div className="Stepnine">
          <div className="row itemDetails">
            <div className="col-lg-6">
              <Form.Label>View Document</Form.Label>
              <div className="">
                <Form.Control
                  type="text"
                  className="me-3"
                  value={adminPdfName}
                  disabled
                />
              </div>
             
              <div className="row">
                <div className="col-md-7">
                  <div className="view-pdf-holder">
                    {pdfUrl && (
                      <iframe
                        className="pdf-holder"
                        src={pdfUrl}
                        title="Admin Uploaded PDF"
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <Form.Label>Upload Document</Form.Label>
              <div className="">
                <Form.Control type="file" className="me-3" onChange={handleFileUpload} />
              </div>
              <div className="row">
                <div className="col-md-7">
                  <div className="view-pdf-holder">
                    {uploadedPdfUrl && (
                      <iframe
                        className="pdf-holder"
                        src={uploadedPdfUrl}
                        title="Uploaded PDF"
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end apply_now_btn">
          <div className="registerContinueMain">
            <Button onClick={props.prevStep} className="back-btn me-3">
              Back
            </Button>
            <Button className="tabs-btn" type="button" onClick={props.nextStep}>
              Next
            </Button>
          </div>
        </div>

       
      </section>
    </>
  );
};

export default Stepnine;
