import React, { useState } from "react";
import "./Variants_Holder.css";

function Variants_Holder({ background, borderColor }) {
    const [isActive, setIsActive] = useState(false);

    const variantsColor = {
        background,
    };

    const activeBorderColor = isActive ? { borderColor } : {};

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <section className="Variants_Holder-div">
            <div 
                className={`color-variant-div ${isActive ? 'active' : ''}`} 
                style={activeBorderColor}
                onClick={handleClick}
            >
                <div className="color-variant" style={variantsColor}></div>
            </div>
        </section>
    );
}

export default Variants_Holder;
