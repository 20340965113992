import React, { useState, useEffect, useContext } from "react";
import "./BecomeASeller.css";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Context } from "../../utils/context";
import { sellerRegistrationForm } from "../../utils/apis/common/Common";
import { Country, State, City, Pincode } from "../../utils/apis/master/Master";
import SuccessModal from "../Common_modal/Successfull_Modal/Successfull_Modal";

import { Category } from "../../utils/apis/Product/Product";
import { useNavigate } from "react-router-dom";
import SellerstepForm from "./SellerstepFormMain/SellerstepForm";

const BecomeASeller = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const { getData, IMG_URL, Select2Data } = useContext(Context);
  const [category, setCategory] = useState();
  const [countries, setCountries] = useState();
  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [pincodes, setPincode] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);
  const [imageFile3, setImageFile3] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile([file]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImageFile(null);
      setImagePreview(null);
    }
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile2(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview2(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImageFile2(null);
      setImagePreview2(null);
    }
  };
  const handleImageChange3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile3(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview3(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImageFile3(null);
      setImagePreview3(null);
    }
  };

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (data.password === data.c_password) {
      const fromData = new FormData();
      fromData.append("name", data?.name);
      fromData.append("contact_no", data?.contact_no);
      fromData.append("email", data?.email);
      fromData.append("pan_no", data?.pan_no);
      fromData.append("password", data?.password);
      fromData.append("c_password", data?.c_password);
      fromData.append("store_name", data?.store_name);
      fromData.append("tax_name", data?.tax_name);
      fromData.append("tax_no", data?.tax_no);
      fromData.append("country_id", data?.country_id?.value);
      fromData.append("state_id", data?.state_id?.value);
      fromData.append("city_id", data?.city_id?.value);
      fromData.append("pincode_id", data?.pincode_id?.value);
      fromData.append("category_id", JSON.stringify(data?.category_id?.map(item => item?.value)));
      fromData.append("n_identity_card", data?.national_identity_card[0]);
      fromData.append("address_proof", data?.address_proof[0]);
      fromData.append("logo", data?.logo[0]);
      const res = await sellerRegistrationForm(fromData);
      if (res?.success) {
        console.log(res);
        reset();
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          navigate('/');
        }, 3000);

      } else {
        if (res?.message?.both) {
          setError('email', { message: res?.message?.both });
          setError('contact_no', { message: "" });
        }
        if (res?.message?.email) {
          setError('email', { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError('contact_no', { message: res?.message?.contact_no });
        }
        if (res?.message?.pan_no) {
          setError('pan_no', { message: res?.message?.pan_no });
        }
        if (res?.message?.tax_no) {
          setError('tax_no', { message: res?.message?.tax_no });
        }
      }
    } else {
      setError("password", {
        message: "Password Must Match",
      });
    }
  }
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const handleKeyPressPan = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (new RegExp('[A-Z]{5}[0-9]{4}[A-Z]{1}').test(keyValue)) {
      event.preventDefault();
    }
  };
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      const data = await Select2Data(res?.data, 'category_id', false);
      setCategory(data);
    }
  }
  const getCountries = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res?.data, 'country_id', false);
      setCountries(data);
    }
  }
  const getState = async (id) => {
    const res = await State(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'state_id', false);
      setState(data);
      setValue('state_id', "");
      setValue('city_id', "");
      setValue('pincode_id', "");
    }
  }
  const getCity = async (id) => {
    const res = await City(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'city_id', false);
      setCity(data);
      setValue('city_id', "");
      setValue('pincode_id', "");
    }
  }
  const getPincode = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'pincode_id', false);
      setPincode(data);
      setValue('pincode_id', "");
    }
  }
  useEffect(() => {
    getCountries();
    getCategory();
  }, []);
  return (
    <>
      <section className="become-a-seller">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-10 mx-auto">
              <div className="image-seller text-center">
                <img src={process.env.PUBLIC_URL + "/assets/Image/become-seller/become-seller.png"} className="become-img" />
              </div>
            </div> */}
            {/* <div className="col-xxl-7  col-xl-7 col-lg-8 col-md-10 mx-auto">

              <div className=" form-seller">
                <h2 className="mb-4">Seller Registration Form</h2>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} md={6} controlId="formGridname" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "Name is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        onKeyDown={(event) => {
                          if (!/[A-Z-a-z ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="tel"
                        name="contact_no"
                        placeholder="Mobile Number"
                        {...register("contact_no", {
                          required: "Phone is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        onKeyPress={handleKeyPressContact}
                        maxLength={10}
                      />
                      {errors?.contact_no && (
                        <sup className="text-danger">{errors?.contact_no?.message}</sup>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridEmail" className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="E-mail Address"
                        {...register("email", {
                          required: "Email Id required",
                          pattern: {
                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                            message: "Email Id Invalid",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}
                      />
                      {errors?.email && (
                        <sup className="text-danger">{errors?.email?.message}</sup>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="text"
                        name="password"
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "password is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.password,
                        })}
                      />
                      {errors?.password && (
                        <span className="text text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="text"
                        name="c_password"
                        placeholder="Enter Confirm Password"
                        {...register("c_password", {
                          required: "Confirm Password is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.c_password,
                        })}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Store Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="store_name"
                        placeholder="Enter Store Name"
                        {...register("store_name", {
                          required: "Store Name is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.store_name,
                        })}
                        onKeyDown={(event) => {
                          if (!/[A-Z-a-z ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Tax Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="tax_name"
                        placeholder="Enter Tax Name"
                        {...register("tax_name", {
                          required: "Tax Name is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.tax_name,
                        })}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridURL" className="mb-3">
                      <Form.Label>Tax Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="tax_no"
                        placeholder="Enter Tax Number"
                        {...register("tax_no", {
                          required: "tax number is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.tax_no,
                        })}
                      />
                      {errors?.tax_no && (
                        <sup className="text-danger">{errors?.tax_no?.message}</sup>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>PAN Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="pan_no"
                        placeholder="PAN Number"
                        {...register("pan_no", {
                          required: "pan number is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.pan_no,
                        })}
                        onKeyPress={handleKeyPressPan}
                        maxLength={10}
                      />
                      {errors?.pan_no && (
                        <sup className="text-danger">{errors?.pan_no?.message}</sup>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>Categories <sub>(Select multiple category)</sub></Form.Label>
                      <Controller
                        name="category_id"
                        {...register("category_id", {
                          required: "Select Category",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Category"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.category_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            isMulti
                            options={category}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Controller
                        name="country_id"
                        {...register("country_id", {
                          required: "Select Country",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Country"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.country_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={countries}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getState(selectedOption.value);
                              setValue("country_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Controller
                        name="state_id"
                        {...register("state_id", {
                          required: "Select State",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="State"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.state_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={states}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getCity(selectedOption.value);
                              setValue("state_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>City</Form.Label>
                      <Controller
                        name="city_id"
                        {...register("city_id", {
                          required: "Select City",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="City"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.city_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={cities}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              getPincode(selectedOption.value);
                              setValue("city_id", selectedOption);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6} controlId="formGridMobile" className="mb-3">
                      <Form.Label>Pincode</Form.Label>
                      <Controller
                        name="pincode_id"
                        {...register("pincode_id", {
                          required: "Select Pincode",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Pincode"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.pincode_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={pincodes}
                          />
                        )}
                      />
                    </Form.Group>

                    <Row>
                      <Col lg={6}>
                        <Form.Group as={Col} md={12} controlId="formGridMobile" className="mb-3">
                          <Form.Label>National Identity Card</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.national_identity_card,
                            })}
                            type="file"
                            {...register("national_identity_card", {
                              required: "National Identity Card is required",
                            })}
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                        </Form.Group>
                      </Col>
                   
                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label column sm={3} className="text-center">
                              Image Preview
                            </Form.Label>
                            <Col sm={9}>
                              {imageFile && imageFile.length > 0 && (
                                <div className="image-preview-container">
                                  <img
                                    src={imagePreview}
                                    alt="Preview"
                                    className="image-preview"
                                    style={{ width: 100, height: 100, marginLeft: 50 }}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group as={Col} md={12} controlId="formGridMobile" className="mb-3">
                          <Form.Label>Address Proof</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.address_proof,
                            })}
                            type="file"
                            {...register("address_proof", {
                              required: "Address Proof is required",
                            })}
                            accept="image/*"
                            onChange={handleImageChange2}
                          />
                        </Form.Group>
                      </Col>
                     
                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label column sm={3} className="text-center">
                              Image Preview
                            </Form.Label>
                            <Col sm={9}>
                              {imageFile2 && (
                                <div className="image-preview-container">
                                  <img
                                    src={imagePreview2}
                                    alt="Preview"
                                    className="image-preview"
                                    style={{ width: 100, height: 100, marginLeft: 50 }}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group as={Col} md={12} controlId="formGridMobile" className="mb-3">
                          <Form.Label>Logo</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.logo,
                            })}
                            type="file"
                            {...register("logo", {
                              required: "Logo is required",
                            })}
                            accept="image/*"
                            onChange={handleImageChange3}
                          />
                        </Form.Group>
                      </Col>
                     
                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label column sm={3} className="text-center">
                              Image Preview
                            </Form.Label>
                            <Col sm={9}>
                              {imageFile3 && (
                                <div className="image-preview-container">
                                  <img
                                    src={imagePreview3}
                                    alt="Preview"
                                    className="image-preview"
                                    style={{ width: 100, height: 100, marginLeft: 50 }}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  <div className="text-center mt-4">
                    <Common_Button className="btn-sub" text="Submit" onClick={handleSubmit(onSubmit)} />
                  </div>
                </Form>
              </div>
            </div> */}

            <div className="col-xxl-12  col-xl-10 col-lg-12 col-md-12 mx-auto">
              <SellerstepForm />
            </div>
          </div>
        </div>
      </section>
      <SuccessModal show={modalShow} onHide={() => setModalShow(false)} message={"Seller Created SuccessFully"} subMessage={"Your details will be sent on email"} />
    </>
  );
};

export default BecomeASeller;
