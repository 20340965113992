import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { RegxExpression } from "../../../../utils/common";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
function Stepfive(props) {
  const [selectedOption, setSelectedOption] = useState(null);

  const optionsnew = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  const uploader = Uploader({
    apiKey: "free"
  });


  const options = { multi: true };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  return (
    <>

      <section className="personal-details-form userForm">
        <div className="details-form-holder">
          <div className="form-container">
            <Form >
              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="required">PAN Number*</Form.Label>
                        <p className="required" style={{ color: "#DF4223" }}>Verify</p>

                      </div>
                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={optionsnew}
                        classNamePrefix="buisness"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">PAN Doc. Upload Type*</Form.Label>
                        <div className="row">
                          <div className="col-lg-6">
                            <Form.Check
                              type="radio"
                              label="Image"
                              id="Image-1"
                              name="name-1"
                            />

                          </div>
                          <div className="col-lg-6">
                            <Form.Check
                              type="radio"
                              label="Document"
                              id="Image-1"
                              name="name-1"
                            />

                          </div>


                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>


              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-field mb-4">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">PAN Image Upload*</Form.Label>
                        <div>
                          <UploadButton uploader={uploader}
                            options={options}
                            onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                            {({ onClick }) =>
                              <button onClick={onClick}>
                                Upload a file...
                              </button>
                            }
                          </UploadButton>

                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required form-field">
                          GST Number
                        </Form.Label>

                        <div className="get-otp-text">
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter GST"

                          />

                        </div>




                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Form className="field-bottom">
                      <Form.Group controlId="Last_Name" >
                        <Form.Label className="required">
                          GST Doc. Upload Type
                        </Form.Label>

                        <div className="row">
                          <div className="col-lg-6">
                            <Form.Check
                              type="radio"
                              label="Image"
                              id="Image-1"
                              name="name-1"
                            />

                          </div>
                          <div className="col-lg-6">
                            <Form.Check
                              type="radio"
                              label="Document"
                              id="Image-1"
                              name="name-1"
                            />

                          </div>


                        </div>
                      </Form.Group>
                    </Form>
                  </div>

                 
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className=" text-end apply_now_btn">


          <Button onClick={props.prevStep} className="back-btn me-3">
            Back
          </Button>

          {/* <div className="registerContinueMain"> */}
          <Button type="button" onClick={props.nextStep} className="tabs-btn">
            Next
          </Button>

          {/* </div> */}
        </div>

      </section>
    </>
  );
}

export default Stepfive;
