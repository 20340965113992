import React from 'react'
import  "./blogbannn.css"

const Blog_vdo = () => {
    return (
        <>
            <section className='blogvdo-sec mb-2'>
                <div className='container p-0'>
                    <div className="vdooseecc mt-3">
                        <video className='video' controls autoPlay loop mute >
                            <source className="vdooo" src={process.env.PUBLIC_URL + '/assets/Image/blog/blogs.mp4'}></source>
                        </video>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog_vdo