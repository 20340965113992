import React from "react";
import "../wallet.css";
import { Form } from "react-bootstrap";
const CoupenCard = () => {
  return (
    <>
      <div className="coupon-div">
        <div className="row">
          <div className="col-6">
            <Form className="">
              <Form.Control
                className="input-coupon"
                type="email"
                placeholder="Enter email"
                value="ABCDEEE"
                readOnly
              />
            </Form>
          </div>
          <div className="col-6">
            <div className="apply-button text-end">
              <button className="btn btn-apply">APPLY</button>
            </div>
          </div>
        </div>
        <h6 className="flat-p">Flat Rs 100 OFF</h6>
        <p className="plat-p-subtext">Lorem ipsum dolor sit amet</p>
      </div>
    </>
  );
};

export default CoupenCard;
