import React, { useEffect } from 'react'
import ForgotPassword from './ForgotPassword/ForgotPassword'

function ChangePassword() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    
    <>
    <ForgotPassword/>
    </>
  )
}

export default ChangePassword