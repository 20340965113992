import React, { useEffect } from "react";
import "./wallettranctionprofile.css";
import deleteimg from "./image/delete-sweep.png";
import { Link } from "react-router-dom";
import Delete_Address from "../Common_modal/Delete_Address/Delete_Address";
import Delete_Transactions from "../Common_modal/Delete_Transactions/Delete_Transactions";
const WalletTranctionProfile = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="wallet desk-top-view-wallet">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mt-3">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/wallet">Wallet</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {" "}
                  Wallet Transactions
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="d-flex justify-content-end mt-4 mb-5">
              <div className="amount-details pt-2">
                <p>Available Transactions : ₹ 100</p>
              </div>
              <div className="button-recharge ms-3">
                <Link to="/wallet">
                  {" "}
                  <button className="btn btn-recharge">Recharge</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="table-responsive">
              <table className="table   wallet-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Transaction Amount</th>
                    {/* <th>Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Aug 15,2023</td>
                    <td>03:45 PM</td>
                    <td>0</td>
                    {/* <td>
                      <img
                        src={deleteimg}
                        className="delete-img"
                        type="button"
                        onClick={() => setModalShow(true)}
                      />
                    </td> */}
                  </tr>
                  <tr>
                    <td>Aug 15,2023</td>
                    <td>03:45 PM</td>
                    <td>500</td>
                    {/* <td>
                      <img src={deleteimg} className="delete-img" />
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Delete_Transactions
        message={"Are you sure you want to permanently delete this history?"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default WalletTranctionProfile;
