import React, { useEffect } from 'react'
import ScanMartStore from './Scan-mart-store/ScanMartStore'


const NearMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <ScanMartStore />
 
    </>
  )
}

export default NearMe